import Mustache from 'mustache';

const config = {
    commonServiceURL: `/as/_FFFFFFFFFFFFFF20170326103602132008_/at/_FFFFFFFFFFFFFF20170326103602132008_/module/{{moduleName}}/api/{{API}}`
};

async function callCommonServiceAPI(...args) {
    let moduleName, API, data, dataRow, client, viewport, formData;
    if(typeof args[0] === "object") {
        ({dataRow, client, viewport, formData} = args[0]);
        [moduleName, API, data] = args.slice(-3);
    }
    else {
        [moduleName, API, data] = args;
    }
    let response = await fetch(
        `${Mustache.render(config.commonServiceURL, {moduleName, API})}`,
        {
            method: 'POST',
            body: JSON.stringify(Object.assign( {}, data,
                (dataRow ? { dataRow } : {}), (client ? { client } : {}), (viewport ? { viewport } : {}), (formData ? { formData } : {}) )),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            mode: 'cors'
        }
    ).catch((e)=> {
        return false;
    });

    let responseObj;
    if(response.ok && (responseObj = await response.json())) {
        return responseObj;
    }
    return false;
}

export {
    callCommonServiceAPI
}
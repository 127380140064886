/**
 * DO NOT ALTER OR REMOVE COPYRIGHT NOTICES OR THIS HEADER.
 *
 * � 2010-2015 Lotus Interworks Inc. (�LIW�) Proprietary and Trade Secret.
 * Do not copy distribute or otherwise use without explicit written permission from B. Gopinath President.
 * Do not communicate or share the information contained herein with any one else except employees of LIW  on a need to know basis.
 * LIW values its intellectual properties and excepts all those who work with LIW to protect all work, including ideas, designs, processes,
 * software and documents shared or created in any engagement with LIW as proprietary to LIW.
 * This document may make references to open sourced software being considered or used by LIW.
 * Extensions, including modifications to such open source software are deemed proprietary and trade secret to LIW  until
 * and unless LIW formally and with explicit written consent contributes specific modified open source code back to open source.
 * In any event, including cases where modified open sourced components are placed in open source, the selection, interconnection,
 * configuration, processes, designs, implementation of all technology, including opens source software,
 * that is being developed or is part of LIW deployed systems are proprietary and trade secret to LIW and
 * such information shall not be shared with any one else except employees of LIW on a need to know basis.
 *
 */

import $ from 'jquery';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import * as async from 'async-es';
import * as oxygenUtil from './../OxygenUtil/oxygenutil.js';

export default class InputParser {
    constructor(inputElementReference, options) {
        this.inputElement = inputElementReference;
        this.options = options || {};
        this.gdnApiUrl = '/CommonServices-0025/System/ServerSide/guid';
        this._addEvents();
        this._setOptions();
    }

    _addEvents() {
        $(this.inputElement).keyup((ev)=>{
            if (ev.keyCode === 13) {
                this._parseInput($(this.inputElement).val(), 'enter');
            }
            else {
                this._parseInput($(this.inputElement).val(), false);
            }

        });
        $(this.inputElement).blur((ev)=>{
            this._parseInput($(this.inputElement).val(), 'blur');
        });
    }

    _parseInput(inputVal, endCondition) {
        let val = inputVal, matches;
        /*
        if(matches = /^.*\s+(.+)/.exec(inputVal)) {
            val = matches[1];
        }

         */
        let entry;
        if(entry = Object.entries(this.parseOptions).find((e)=>{
            return e[1](val);
        })) {
            if(this.inputCallback) {
                return this.inputCallback({value: val, type: entry[0]});
            }
        }

        if(endCondition) {
            this._parseForEndConditions(val, endCondition);
        }
    }

    _parseForEndConditions(inputVal, endCondition) {
        //console.log('_parseForEndConditions - endCondition:', endCondition);
        async.someSeries(this.endParseOptions, (e, callback) => {
            //console.log('_parseForEndConditions - e:', e);
            if(e.conditions[endCondition]) {
                e.fn(inputVal.trim(), (error, valid) => {
                    //console.log('_parseForEndConditions - valid:', valid);
                    if(valid) {
                        callback(null, this.inputCallback({value: inputVal.trim(), type: e.name}));
                    }
                    else {
                        callback(null, false);
                    }
                });
            }
            else {
                callback(null, false);
            }
        },()=>{

        });
    }

    _isEmail(noSpace, email, callback) {
        //console.log('_isEmail - arguments:', arguments);
        let regex;
        if(noSpace) {
            regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
        }
        else {
            regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+\s+$/;
        }
        let isEmail = regex.test(email);
        //console.log('_isEmail - isEmail:', isEmail);
        if(typeof callback === "function") {
            callback(null, isEmail);
        }
        else {
            return isEmail;
        }
    }

    _setOptions() {
        this.parseOptions = Object.assign({ email: this._isEmail.bind(this, false), phone: this._formatPhoneNumber.bind(this) }, this.options.parseOptions | {});
        this.endParseOptions = [
            {name: 'email', fn: this._isEmail.bind(this, true), conditions: { blur: true, enter: true } },
            {name: 'component', fn: this._getComponentGUIDFromGDN.bind(this), conditions: { blur: true, enter: true } },
            {name: 'channel', fn: this._isChannel.bind(this), conditions: { blur: true, enter: true } },
            {name: 'string', fn: this._isString.bind(this), conditions: { blur: false, enter: true } },
        ]
        this.inputCallback = this.options.inputCallback;
    }

    _isChannel(val, callback) {
        callback(null, /^@/.test(val));
    }

    _isString(val, callback) {
        callback(null, !!val);
    }

    _formatPhoneNumber(phoneNumberString) {

        let numberObj = parsePhoneNumberFromString(phoneNumberString, 'US');
        //console.log('_formatPhoneNumber', 'num:', phoneNumberString, ' obj:', numberObj);
        if(numberObj && numberObj.isValid()) {
            return numberObj.format('E.164');
        }
        return false;
    }

    _getComponentGUIDFromGDNOld(GDN, callback) {
        if(GDN && GDN.length > 7 ) {
            $.ajax({
                url: this.gdnApiUrl,
                type: "GET",
                data: {
                    GDN
                },
                success: function (res) {
                    callback(null, res);
                },
                error: (jXHR, error) => {
                    callback(null, false);
                }
            });
        }
    }

    _getComponentGUIDFromGDN(GDN, callback) {
        setTimeout( async ()=>{
            if(GDN) {
                let component = await oxygenUtil.getComponentByDisplayName(GDN);
                callback(null, component);
            }
            else {
                callback(null, false);
            }
        },0);


    }
}


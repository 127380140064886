<template>

    <div ref="headerContainer" v-show="showHeader"  :style="headerStyleObject">
   <div v-show="showTopHeader" class="main-panel-header-title" >
        <span class="header-left-container">
            <span class="far fa-home oxygen-home-icon " @click="homeIconClicked" ></span>&nbsp;
            <span class="simplia-panel-title">
                <span ref="detailsIcon" v-show="!showDetails" @click="displayDetails" class="fas fa-info" style="color: lightblue;  cursor: pointer; padding-top:3px; padding-right:10px;"></span>
                <span v-if="showPanelIndexNum"  style="display:inline-flex; align-items: center;">
                    <span
                        :data-index='dataParentindex'
                        class='panel-index'
                        :style="{'font-size':'16px', 'font-weight': oxygenUtil.config.headerFontWeight}"
                    >{{dataParentindex}}</span>/<span

                    :data-index='dataLocalindex'
                    class='panel-index'
                    :style="{'font-size':'16px', 'font-weight': oxygenUtil.config.headerFontWeight}"
                >{{dataLocalindex}}</span>
                </span>
                &nbsp;
                <template v-if="showThreadSubjectTitle" >
                    <span :style="{'font-size':'16px', 'font-weight': oxygenUtil.config.headerFontWeight}" class="thread-subject-title" v-if="rootMenuItem.PrettyURL">
                      {{rootMenuItem.PrettyURL}}
                    </span>
                    <span class="thread-subject-title" v-else>
                      <span
                          @click="threadRoleClicked"
                          class="far fa-share-alt title-thread-role"
                          v-show="showThread && showDetails">
                      </span>
                      <span :style="{'font-size':'16px', 'font-weight': oxygenUtil.config.headerFontWeight}" v-tooltip="{content: dataLeftdn || ''}"  class="title-component title-thread">
                        {{oxygenUtil.formatDN(dataLeftdn)}}
                      </span>
                      <span v-show="showThread">&nbsp;/&nbsp;</span>
                      <span
                          v-show="showDetails"
                          @click="subjectRoleClicked"
                          class="far fa-share-alt title-subject-role">
                      </span>
                      <span :style="{'font-size':'16px', 'font-weight': oxygenUtil.config.headerFontWeight}" v-tooltip="{content: dataRightdn || ''}"  class="title-component title-subject">
                         {{oxygenUtil.formatDN(dataRightdn)}}
                      </span>
                      <span :style="{'font-size':'16px', 'font-weight': oxygenUtil.config.headerFontWeight}" v-if="rootMenuItem.extendedTitle">
                      &nbsp;/&nbsp;{{rootMenuItem.extendedTitle}}
                      </span>
                    </span>
                </template>
                <span :style="{'font-size':'16px', 'font-weight': oxygenUtil.config.headerFontWeight}" class="thread-subject-title" v-else>
                  {{simpleTitle}}
                </span>
            </span>
        </span>
        <span class="rightmenu-container">
          
            <span
                class="screen-size-icon far fa-ellipsis-v"
                style="padding-right:12px;"
                @click="toggleRibbon"
                v-tooltip="{content: toggleRibbonTooltip || ''}"
            ></span>
            <span
                class="screen-size-icon fal fa-square-full"
                @click="screenOptionsClick"
                v-tooltip="{content: 'Screen Options'}"
            ></span>
            <!--
          <span
              class="screen-size-icon far fa-window-minimize"
              @click="minimizeClicked"
          ></span> -->
          <span
              class="screen-size-icon screen-size-icon-first fas fa-times"
              style="color: #0072c5;"
              @click="closeClicked"
              v-tooltip="{content: 'Close'}"
          ></span>

        </span>
    </div>
   <oxygen-ribbon ref="screenRibbon" v-show="showScreenRibbon"></oxygen-ribbon>
   <oxygen-ribbon ref="headerRibbon"></oxygen-ribbon>



   <menu-modal
       ref="roleModal"
       :table-data="rolesTableData"
       :modal-name="roleModalName"
       :panel-title="roleModalTitle"
       :menu-items="roles"
       :styles="roleModalStyles"
   />
   <p-e-details
       :detailsData="detailsData"
       ref="detailsContainer"
       :subjectRoleChange="subjectRoleChange"

   ></p-e-details>
   <form-container
       :title="formContainerTitle"
       :form-rows="formRows"
       ref="formContainer"
   ></form-container>
 </div>
  <div v-show="!showHeader" style="display:inline-block; width:100%">
    <span
        class="screen-size-icon far fa-bars"
        style="padding-right:12px;color: #0072c5; float: right;"
        @click="restoreHeader"
        v-tooltip="{content: 'Restore'}"
    ></span>
  </div>

  <options-menu
      :title="menuTitle"
      :container-name="menuName"
      :options="menuOptions"
      :table-params="menuTableParams"
      :show-checkbox="false"
      :show-folder="false"
      ref="optionsMenu"
  ></options-menu>

  <child-container
      :container-name="containerName"
      :container-title="title"
      ref="container"
      v-on:container-hidden="containerHidden"
      v-on:container-shown="containerShown"
      :width="frameWidth"
      z-index="1000"
  >
    <iframe ref="frame" :width="frameWidth" :style="frameStyle" src="https://debianlargeserver-0050-simpliasites.laxroute53.com/SoumyanilNode/help/"></iframe>
  </child-container>
</template>

<script setup>
  import { ref, reactive, onMounted, watch, nextTick } from 'vue';
  import * as async from 'async-es';
  import OxygenRibbon from './../../OxygenRibbon/OxygenRibbon.ce.vue';
  import * as oxygenUtil from "./../../OxygenUtil";
  import MenuModal from './../../MenuModal/MenuModal.ce.vue';
  import PEDetails from './../../PEDetails/PEDetails.ce.vue';
  import FormContainer from './../../FormContainer/FormContainer.ce.vue';
  import OptionsMenu from './../../OptionsMenu/OptionsMenu.ce.vue';
  import { vTooltip } from 'floating-vue';
  import ChildContainer from './../../ChildContainer/ChildContainer.ce.vue';

  const props = defineProps({
    showDetails: {
      type: Boolean,
      default: false
    },
    dataLeftdn: {
      type: String,
      default: 'LeftDN'
    },
    dataRightdn: {
      type: String,
      default: 'RightDN'
    },
    dataParentindex: {
      type: Number,
      default: 0
    },
    dataLocalindex: {
      type: Number,
      default: 0
    },
    dataFnobj: {
      type: String
    },
    dataDisplayheaderribbon: {
      type: Boolean,
      default: true
    }
  });

  let headerStyleObject = ref({width: '100%', 'font-size': '16px'});

  let showHeader = ref(true),
      showTopHeader = ref(true),
      showPanelIndexNum = ref(true),
      showThreadSubjectTitle = ref(true),
      simpleTitle= ref(""),
      showThread = ref(true),
      viewport = reactive({}),
      showHeaderRibbon = ref(props.dataDisplayheaderribbon),
      showScreenRibbon = ref(false),
      headerRibbon = ref(null),
      screenRibbon = ref(null),
      rootMenuItem = reactive({}),
      rightMenu = ref(null),
      client = ref({}),
      PEDetailsOpenPEMode = ref(true),
      showSchedulerButtonRibbon = ref(true),
      availabilityForm = ref(null),
      appointmentForm = ref(null),
      appointmentsList = ref(null);

  let containerName = `container${oxygenUtil.getUUID()}`, container = ref(null), title = ref('Help');
  let frameStyle = ref( {height: '230px', border: 'none'}), frameWidth = ref('400px'), frame = ref('');

  function containerHidden() {

  }

  function containerShown() {

  }

  //const store = deviceStore();

  const list = reactive([
    { id: 1},
    {id: 2}
  ]);

  watch(
      ()=> props.dataDisplayheaderribbon,
      (newVal)=>{
        //console.log('watch-showribbon:', newVal);
        showHeaderRibbon.value = newVal;

      },
      {
        immediate: true
      }
  );








  let allModeIconInfo = { type: 'html',
    html: `<span title="All" class="far fa-address-book"></span>` },
  liveModeIconInfo = { type: 'html',
    html: `<span title="Live" style="color: green;" class="far fa-address-book"></span>` },
  meetingModeIconInfo = { type: 'html',
    html: `<span title="Meeting" style="color: red;" class="far fa-address-book"></span>` };
  const leftMenu = ref(null);

  let iconMap = {
    'all': allModeIconInfo,
    'live': liveModeIconInfo,
    'meeting': meetingModeIconInfo
  }


  const menuTitle = ref('Mode'), optionsMenu = ref(), menuName = ref(`id${oxygenUtil.getUUID()}`), menuOptions = ref([]), menuTableParams = reactive({
    showCheckbox: false,
    showFolder: false,
    showRightMenu: false
  });

  let headerRibbonIcons;
  function setRibbonIcons() {
    headerRibbonIcons = reactive([
      {
        id: "left_menu",
        iconInfo: { type: "css", classes: "far fa-ellipsis-v", color: '#0072c5'},
        callback: ()=>{
          leftMenu.value.showMenu(headerRibbon.value.getIconElement('left_menu'));
        },
        orientation: 'left',
        toolTip: "UI Options"
      },
      {

        id: "navigate_left_end",
        iconInfo: { type: "css", classes: "fa-light fa-arrow-left-to-line", color: '#0072c5'},
        callback: ()=>{
          window[props.dataFnobj].navigateLeftEnd();
        },
        orientation: 'left',
        toolTip: "History Start"
      },
      {

        id: "navigate_left",
        iconInfo: { type: "css", classes: "fa-light fa-arrow-left", color: '#0072c5'},
        callback: ()=>{
          window[props.dataFnobj].navigateLeft();
        },
        orientation: 'left',
        toolTip: "History Back"
      },
      {

        id: "navigate_right",
        iconInfo: { type: "css", classes: "fa-light fa-arrow-right", color: '#0072c5'},
        callback: ()=>{
          window[props.dataFnobj].navigateRight();
        },
        orientation: 'left',
        toolTip: "History Next"
      },
      {

        id: "navigate_right_end",
        iconInfo: { type: "css", classes: "fa-light fa-arrow-right-to-line", color: '#0072c5'},
        callback: ()=>{
          window[props.dataFnobj].navigateRightEnd();
        },
        orientation: 'left',
        toolTip: "History End"
      },
      {

        id: "angle_left",
        iconInfo: { type: "css", classes: "fa-light fa-angle-left", color: '#0072c5'},
        callback: ()=>{
          window[props.dataFnobj].angleLeft();
        },
        orientation: 'left',
        toolTip: "Left"
      },
      {

        id: "angle_up",
        iconInfo: { type: "css", classes: "fa-light fa-angle-up", color: '#0072c5'},
        callback: ()=>{
          window[props.dataFnobj].angleUp();
        },
        orientation: 'left',
        toolTip: "Up"
      },
      {

        id: "angle_down",
        iconInfo: { type: "css", classes: "fa-light fa-angle-down", color: '#0072c5'},
        callback: ()=>{
          window[props.dataFnobj].angleDown();
          //let event = new CustomEvent(`show-menu-${eventGuid2}`, {});
          //window.top.dispatchEvent(event);


        },
        orientation: 'left',
        toolTip: "Down"
      },
      {

        id: "angle_right",
        iconInfo: { type: "css", classes: "fa-light fa-angle-right", color: '#0072c5'},
        callback: ()=>{
          window[props.dataFnobj].angleRight();
        },
        orientation: 'left',
        toolTip: "Right"
      },
      {
        id: "right_menu",
        iconInfo: { type: "css", classes: "far fa-ellipsis-v", color: '#0072c5'},
        callback: ()=>{

          rightMenu.value.showMenu(headerRibbon.value.getIconElement('right_menu'), 'right');
        },
        orientation: 'right',
        toolTip: "All Actions"
      },
      {
        id: "help",
        iconInfo: { type: "css", classes: "fas fa-question", color: 'lightblue'},
        callback: ()=>{
          container.value.showModalAlignedToElement(headerRibbon.value.getIconElement('help') , 'right', 'bottom')
        },
        orientation: 'right',
        toolTip: "Help"
      }/*,
      {
        id: "expand",
        iconInfo: { type: "css", classes: "far fa-angle-double-down", color: '#0072c5'},
        callback: ()=>{

        },
        orientation: 'right',
        toolTip: "Expand"
      },
      {
        id: "show_help",
        iconInfo: { type: "css", classes: "fal fa-question-circle", color: '#0072c5'},
        callback: ()=>{

        },
        orientation: 'right',
        toolTip: "Show Help"
      }*/
    ]);
  }

  let searchMode = ref(false);
  function toggleSearch() {
    searchMode.value = !searchMode.value;
    let event = new CustomEvent(`${props.viewportID}-toggle-search`, {
      detail: {
        SearchMode: searchMode.value,
        ViewportID: props.viewportID
      }
    });
    window.dispatchEvent(event);
  }

  const screenRibbonIcons = reactive([
    {
      id: "hide",
      iconInfo: { type: "css", classes: "fas fa-times", color: '#0072c5'},
      callback: ()=>{
        showHeaderRibbon.value = lastHeaderRibbonState;
        showScreenRibbon.value = false;

      },
      orientation: 'right',
      toolTip: "Hide the Toolbar"
    },
    {
      id: "expand",
      iconInfo: { type: "css", classes: "far fa-angle-double-down ", color: '#0072c5'},
      callback: ()=>{

      },
      orientation: 'right',
      toolTip: "Expand"
    },
    /*{
      id: "enlarge_font",
      iconInfo: { type: "css", classes: "far fa-font enlarge-font-icon"},
      callback: ()=>{

      },
      orientation: 'right',
      toolTip: "Enlarge Font Size"
    },
    {
      id: "reduce_font",
      iconInfo: { type: "css", classes: "far fa-font reduce-font-icon"},
      callback: ()=>{

      },
      orientation: 'right',
      toolTip: "Reduce Font Size"
    },*/
    {
      id: "full_screen",
      iconInfo: { type: "css", classes: "fal fa-expand-wide ", color: '#0072c5'},
      callback: setFullScreen,
      orientation: 'right',
      toolTip: "Full screen Mode"
    },
    {
      id: "desktop",
      iconInfo: { type: "css", classes: "far fa-desktop-alt ", color: '#0072c5'},
      callback: setDesktopMode,
      orientation: 'right',
      toolTip: "Desktop Mode"
    },
    {
      id: "laptop",
      iconInfo: { type: "css", classes: "far fa-laptop ", color: '#0072c5'},
      callback: setLaptopMode,
      orientation: 'right',
      toolTip: "Laptop Mode"
    },
    {
      id: "tablet",
      iconInfo: { type: "css", classes: "fas fa-tablet-alt ", color: '#0072c5'},
      callback: setTabletMode,
      orientation: 'right',
      toolTip: "Tablet Mode"
    },
    {
      id: "mobile",
      iconInfo: { type: "css", classes: "fas fa-mobile-alt ", color: '#0072c5'},
      callback: setMobileMode,
      orientation: 'right',
      toolTip: "Mobile Mode"
    }/*,
    {
      id: "settings",
      iconInfo: { type: "css", classes: "fat fa-gears "},
      callback: showUISettings,
      orientation: 'right',
      toolTip: "UI Settings"
    }*/
  ]);

  const headerContainer = ref(null);
  function setFullScreen() {
    //window.headerContainer = headerContainer;
    let terminationElement = document.getElementById();
    requestFullScreen(terminationElement);
  }

  function requestFullScreen(docElm) {
    //console.log('requestFullScreen', 'docElm:', docElm, ' - reqFullScr:', docElm.requestFullscreen);
    if (docElm.requestFullscreen) {
      docElm.requestFullscreen();
    } else if (docElm.mozRequestFullScreen) {
      docElm.mozRequestFullScreen();
    } else if (docElm.webkitRequestFullScreen) {
      docElm.webkitRequestFullScreen();
    } else if (docElm.msRequestFullscreen) {
      docElm.msRequestFullscreen();
    }
    else {
      //console.log('requestFullScreen', 'returning false');
      return false;
    }
    //console.log('requestFullScreen', 'returning true');
    return true;
  }

  function setDesktopMode() {
    headerStyleObject.value.width = '100%';
    window[props.dataFnobj].viewportChanged({width: '100%', mode: 'desktop'});
  }

  function setLaptopMode() {
    headerStyleObject.value.width = '1264px';
    window[props.dataFnobj].viewportChanged({width: '1264px', mode: 'laptop'});
  }

  function setTabletMode() {
    headerStyleObject.value.width = '769px';
    window[props.dataFnobj].viewportChanged({width: '769px', mode: 'tablet'});
  }

  function setMobileMode() {
    headerStyleObject.value.width = '415px';
    window[props.dataFnobj].viewportChanged({width: '415px', mode: 'mobile'});
  }


  let attributionRecord, headerExternalRef;
  onMounted(async ()=>{

    setHeaderExternalRef();
    setRibbonIcons();
    headerRibbon.value.addRibbonIcons(headerRibbonIcons);
    screenRibbon.value.addRibbonIcons(screenRibbonIcons);
    setMenuOptionsEvent();
    setShowMenuEvent();
    //addRightMenuOptions();
  });

  function setHeaderExternalRef() {
    window._viewportHeader = {
      addMenuOptions,
      setMenuTitle
    }
  }

  let eventGuid1 = oxygenUtil.getUUID(), eventGuid2 = oxygenUtil.getUUID();
  function setMenuOptionsEvent() {
    window.addEventListener(`menu-options-${eventGuid1}`, (e)=>{
      menuOptions.value.length = 0;
      console.log('addMenuOptions', 'options:', e.detail.options);
      e.detail.options.forEach((e, i)=>{
        menuOptions.value.push(Object.assign(e, { path: `${i}`.padStart(5, '0')}));
      });
      console.log('addMenuOptions', 'menuOptions:', menuOptions);
    })
  }

  function setShowMenuEvent() {
    window.addEventListener(`show-menu-${eventGuid2}`, (e)=>{
      optionsMenu.value.showMenuAlignedToElement(headerRibbon.value.getIconElement('angle_down') , 'left', 'bottom')
    })
  }


  watch(
      menuOptions,
      ()=> {
        console.log('watch', 'options updated');
      },
      {
        deep: true
      }
  );


  function addMenuOptions(options) {
    let event = new CustomEvent(`menu-options-${eventGuid1}`, {
      detail: {
        options
      }
    });
    window.top.dispatchEvent(event);
  }

  function setMenuTitle(title) {
    menuTitle.value = title;
  }


  function onVisible(element, callback) {
    new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if(entry.intersectionRatio > 0) {
          callback(element);
          observer.disconnect();
        }
      });
    }).observe(element);
  }





  function screenOptionsClick(){
    showHeaderRibbon.value = false;
    showScreenRibbon.value = true;
  }

  function minimizeClicked() {
    window.DevMgr.minimizeWindow(props.viewportID);
  }

  function homeIconClicked() {
    window[props.dataFnobj].homeIconClicked();
  }

  function closeClicked() {
    showHeader.value = false;
  }

  function restoreHeader() {
    showHeader.value = true;
  }

  let roleModal = ref(null),
      roleModalName = ref('roleModal'),
      roleModalTitle = ref('Role'),
      roles = reactive([]),
      rolesTableData = reactive({
        showCheckbox: false
      }),
      roleModalStyles = reactive({});




  function addSavePDFOption() {
    window.DevMgr.addRightMemu(props.viewportID, {
      icon: `fal fa-file-pdf`,
      title: 'Save As PDF',
      callback: async (rightMenuItem)=> {
        formContainerTitle.value = 'PDF Options ';
        formRows.value = [
          {
            type: "select",
            name: "template",
            label: "Template",

            options:[
              { value: '_FF10313609901216306869076411514878_', content: 'PlateTestTemplate'}
            ],
            value: '_FF10313609901216306869076411514878_'
          },
          {
            id: "documentHeight",
            name: "documentHeight",
            type: "radioGroup",
            required: true,
            labelPosition: "top",
            value: "entire",
            options: {
              rows: [
                {
                  type: "radioButton",
                  text: "Entire Document",
                  value: "entire",
                },
                {
                  type: "radioButton",
                  text: "Visible Document",
                  value: "visible"
                },
              ]
            }
          },
          {
            type: "input",
            name: "saveAsName",
            label: "Save As",
            value: client.value.ClientRightDN
          },
          /*{
            type: "input",
            label: 'Email',
            name: 'email'
          },*/
          {
            type: "button",
            css: "spaced-button",
            name: "saveAsPDF",
            text: "Save As PDF",
            size: "medium",
            view: "flat",
            color: "primary"
          }
        ];

        formContainer.value.showContainerAlignedToElementInViewport(
            document.getElementById(props.viewportID).querySelector(".main-panel-header-title"),
            'bottom',
            document.getElementById(props.viewportID).getBoundingClientRect()
        );

        await nextTick();
        //console.log('saveAsPDF-callback', 'formContainer:', formContainer);
        window._formContainer = formContainer.value;
        formContainer.value.formInstance.getItem("saveAsPDF").events.on("click", async ()=>{
          //formInstance.getItem("email").getValue()
          //console.log('saveAsPDF-callback');
          //await postEmailPDFHistoryTask();
          let component = await makePDF(formContainer.value.formInstance.getItem("saveAsName").getValue(), formContainer.value.formInstance.getItem("documentHeight").getValue() == "entire");
          formContainer.value.hide();
          window.BotServices.PullPE(
              {
                ByClientID : client.value.Client,
                InitialContext : {
                  ByCLientID: client.value.Client
                },
                OpenPE : component.grants.adminGrant.GrantPE
              }
          );
        });
      }

    });
  }

  function addEmailSettingsOption() {
    window.DevMgr.addRightMemu(props.viewportID, {
      icon: `fal fa-envelope`,
      title: 'Email Settings',
      callback: async (rightMenuItem)=> {
        let rows = await oxygenUtil.runDBQuery(
            `select er.LinkedEmail, er.ForeignEmailDomain, er.LinkedEmail,
es.ForeignEmailDomain as ScreeningForeignEmailDomain, es.LocalPE, es.ReplyTemplate, es.TemplateText
from OxygenDatabase.EmailRedirector er, OxygenDatabase.EmailScreening es
where er.LocalPE = ?
and er.LocalPE = es.LocalPE`,
            [ getContextPE() ]
        );

        let existingSettings = {};
        if(rows.length) {
          existingSettings = rows[0];
        }

        formContainerTitle.value = 'Email Redirection Options';
        formRows.value = [
          {
            type: "input",
            name: "LinkedEmail",
            label: "Linked Email",
            value: existingSettings.LinkedEmail || ''
          },
          /*{
            type: "checkbox",
            name: "SkipNotification",
            label: "Skip Notification",
            value: (typeof existingSettings.SkipNotification !== "undefined") ? existingSettings.SkipNotification : true
          },*/
          {
            type: "input",
            name: "ForeignEmailDomain",
            label: "External Service Mail Domain",
            value: existingSettings.ForeignEmailDomain || 'job.craigslist.org'
          },

          {
            type: "input",
            name: "Screening.ForeignEmailDomain",
            label: "Receiving Mail Domain",
            value: existingSettings.ScreeningForeignEmailDomain || 'reply.craigslist.org'
          },
          {
            type: "input",
            name: "ReplyTemplate",
            label: "Reply Email Template",
            value: existingSettings.ReplyTemplate || '_FFFFFFFFFFFFFF00001666165378437733_'
          },
          {
            type: "textarea",
            name: "TemplateText",
            label: "Reply Email Content",
            value: existingSettings.TemplateText || ''
          },

          {
            type: "button",
            css: "spaced-button",
            name: "save",
            text: "Save",
            size: "medium",
            view: "flat",
            color: "primary"
          }
        ];

        formContainer.value.showContainerAlignedToElementInViewport(
            document.getElementById(props.viewportID).querySelector(".main-panel-header-title"),
            'bottom',
            document.getElementById(props.viewportID).getBoundingClientRect()
        );

        await nextTick();

        window._formContainer = formContainer.value;
        formContainer.value.formInstance.getItem("save").events.on("click", async ()=>{
          //formInstance.getItem("email").getValue()
          //console.log('save-callback');
          //await postEmailPDFHistoryTask();
          await oxygenUtil.runDBQuery(
              !rows.length ? `insert into OxygenDatabase.EmailRedirector (LinkedEmail, ForeignEmailDomain, LocalPE ) values (?, ?, ?)` :
                  `update OxygenDatabase.EmailRedirector set LinkedEmail = ?, ForeignEmailDomain = ? where LocalPE = ?`,
              [formContainer.value.formInstance.getItem("LinkedEmail").getValue(), formContainer.value.formInstance.getItem("ForeignEmailDomain").getValue(), getContextPE()]
          );

          await oxygenUtil.runDBQuery(
              !rows.length ? `insert into OxygenDatabase.EmailScreening (ReplyTemplate, ForeignEmailDomain, TemplateText, LocalPE ) values (?, ?, ?, ?)` :
                  `update OxygenDatabase.EmailScreening set ReplyTemplate = ?, ForeignEmailDomain = ?, TemplateText = ? where LocalPE = ?`,
              [formContainer.value.formInstance.getItem("ReplyTemplate").getValue(), formContainer.value.formInstance.getItem("Screening.ForeignEmailDomain").getValue(), formContainer.value.formInstance.getItem("TemplateText").getValue(),getContextPE()]
          );
          if(!rows.length || existingSettings.LinkedEmail !== formContainer.value.formInstance.getItem("LinkedEmail").getValue()) {
            await oxygenUtil.callCommonServiceAPI(
                "simplia-api",
                'addTrackingRecord',
                {
                  relation: 'EmailToPE',
                  PE1: formContainer.value.formInstance.getItem("LinkedEmail").getValue(),
                  PE2: getContextPE(),
                  extraParams: {
                  }
                }
            );
          }
          formContainer.value.hide();
        });
      }

    })
  }

  function addDownloadFileOption() {
    window.DevMgr.addRightMemu(props.viewportID, {
          icon: `fal fa-download`,
          title: 'Download File',
          callback: async (rightMenuItem) => {
            const anchor = document.createElement('a');
            anchor.href = await oxygenUtil.getComponentFileUrl(client.value.ClientRight);
            //console.log('Download File-callback', 'href:', anchor.href);
            let matches = /^.+\/(.+)$/.exec(anchor.href);
            anchor.download = matches[1];
            anchor.click()

          }
        }
    );
  }


  function addRoleTokenOption() {
    window.DevMgr.addRightMemu(props.viewportID, {
      icon: `fal fa-key`,
      title: 'Set Role Tokens',
      callback: async (rightMenuItem)=> {
        let allRoles = await oxygenUtil.getAllComponentsRoles(
            [
              {
                id: client.value.ClientRight,
                defaultRole: client.value.ClientRightRole
              }
            ],
            [],
            ['owner'].concat(client.value.ClientRight !== 'owner' ? 'admin' : []));

        let componentEdge = await oxygenUtil.getComponent(client.value.ClientLeft, client.value.ClientRight);

        formContainerTitle.value = 'Set Role Tokens';
        formRows.value = [
          {
            type: "select",
            name: "roleName",
            label: "Role Name",

            options: [{ value: "", content: ""}].concat(allRoles[client.value.ClientRight].map((e)=>{
              return { value: e.role, content: e.role};
            })),
            value: ''
          },
          {
            type: "input",
            name: "token",
            label: "Role Token",
            value: '',
            placeholder: 'Please enter a 6-digit code',
            errorMessage: "Invalid token",
            validation: (value)=> {
              return /^[\d]{6}$/.test(value);
            }
          },
          {
            type: "button",
            css: "spaced-button",
            name: "add",
            text: "Add",
            size: "medium",
            view: "flat",
            color: "primary"
          }
        ];

        formContainer.value.showContainerAlignedToElementInViewport(
            document.getElementById(props.viewportID).querySelector(".main-panel-header-title"),
            'bottom',
            document.getElementById(props.viewportID).getBoundingClientRect()
        );

        await nextTick();
        formContainer.value.formInstance.getItem("roleName").events.on("change", async()=>{
          let currentRole = formContainer.value.formInstance.getItem("roleName").getValue();
          let tokenVal = componentEdge.RoleTokens && componentEdge.RoleTokens[client.value.ClientLeftRole] &&
              componentEdge.RoleTokens && componentEdge.RoleTokens[client.value.ClientLeftRole][currentRole] ?
              componentEdge.RoleTokens && componentEdge.RoleTokens[client.value.ClientLeftRole][currentRole] : "";
          formContainer.value.formInstance.getItem("token").setValue(tokenVal);

          formContainer.value.formInstance.getItem("add").setProperties({text: tokenVal ? 'Update' : 'Add'});

        });

        formContainer.value.formInstance.getItem("add").events.on("click", async ()=>{
          let roleName, token;
          //console.log('addRole-click', 'role:',  formContainer.value.formInstance.getItem("roleName").getValue());
          if((roleName = formContainer.value.formInstance.getItem("roleName").getValue()) &&
              (token = formContainer.value.formInstance.getItem("token").getValue())) {
            if(/^[\d]{6}$/.test(token)) {
              await oxygenUtil.updateComponentEx(
                  client.value.ClientLeft,
                  {
                    'set': Object.assign({}, componentEdge.RoleTokens ? {
                          [`RoleTokens.${client.value.ClientLeftRole}`] : Object.assign({},
                              (componentEdge.RoleTokens && componentEdge.RoleTokens[client.value.ClientLeftRole]) ?
                                  componentEdge.RoleTokens[client.value.ClientLeftRole] : {},
                              { [roleName] : token })
                        } :
                        {
                          'RoleTokens': {
                            [client.value.ClientLeftRole] : { [roleName] : token }
                          } }),
                    Edge: client.value.ClientRight
                  }
              );
              formContainer.value.hide();
            }
          }
        });
      }

    })
  }

  function addSetAvailabilityOption() {
    window.DevMgr.addRightMemu(props.viewportID, {
      icon: `fal fa-calendar`,
      title: 'Set Availability',
      callback: async (rightMenuItem) => {
        availabilityForm.value.show();
      }
    });
  }

  function addChooseAppointmentOption() {
    window.DevMgr.addRightMemu(props.viewportID, {
      icon: `fal fa-calendar`,
      title: 'Choose Appointment',
      callback: async (rightMenuItem) => {
        appointmentForm.value.show();
      }
    });
  }

  function addShowAppointmentsOption() {
    window.DevMgr.addRightMemu(props.viewportID, {
      icon: `fal fa-calendar`,
      title: 'Show Appointments',
      callback: async (rightMenuItem) => {
        appointmentsList.value.show();
      }
    });
  }

  function addEditContextOption() {
    window.DevMgr.addRightMemu(props.viewportID, {
      icon: `fal fa-pen-to-square`,
      title: 'Edit Context',
      callback: async (rightMenuItem)=> {
        let terminationUrl = {
          "MediaType": "Iframe",
          "URL": "/TemplateSchema/TemplateClient/index.html?componentid=" + client.value.ClientRight
        };
        window.BotServices.openChildPanel({
          ClientID: props.ClientID,
          DisplayName: 'Edit Context',
          Termination: terminationUrl
        }, () => {
        });
      }
    });
  }

  function addChangeDNOption() {
    window.DevMgr.addRightMemu(props.viewportID, {
      icon: `fal fa-file-signature`,
      title: 'Update DisplayName',
      callback: async (rightMenuItem)=> {
        let DNs = await oxygenUtil.getGDN(client.value.ClientRight);
        let uuid = `c${oxygenUtil.getUUID()}`;
        //console.log('Update DisplayName', 'uuid:', uuid);
        formContainerTitle.value = 'Update DisplayName';
        formRows.value = [
          {
            css: uuid,
            type: "combo",
            name: "newDN",
            label: "New DisplayName",
            value: '',
            newOptions: true,
            data: DNs.map( (e)=> {
              return { value: e, id: e};
            })
          },
          {
            type: "button",
            css: "spaced-button",
            name: "update",
            text: "Update",
            size: "medium",
            view: "flat",
            color: "primary"
          }
        ];

        formContainer.value.showContainerAlignedToElementInViewport(
            document.getElementById(props.viewportID).querySelector(".main-panel-header-title"),
            'bottom',
            document.getElementById(props.viewportID).getBoundingClientRect()
        );

        await nextTick();
        window._formContainer = formContainer.value;
        let newDN;

        /*
        formContainer.value.formInstance.getItem("newDN").events.on("beforeChange", ()=>{
          console.log('Update DisplayName', 'val-1:', formContainer.value.formInstance.getItem("newDN").getValue());
          console.log('Update DisplayName', 'val-2:', window.document.querySelector(`.${uuid}`).querySelector("input").value);
          formContainer.value.formInstance.getItem("newDN").getValue() ||
          window.document.querySelector(`.${uuid}`).querySelector("input").value;
        });

         */

        formContainer.value.formInstance.getItem("newDN").events.on("blur", ()=>{
          //console.log('Update DisplayName', 'blur-val-1:', formContainer.value.formInstance.getItem("newDN").getValue());
          //console.log('Update DisplayName', 'blur-val-2:', window.document.querySelector(`.${uuid}`).querySelector("input").value);
          newDN = formContainer.value.formInstance.getItem("newDN").getValue() ||
          window.document.querySelector(`.${uuid}`).querySelector("input").value;
        });

        formContainer.value.formInstance.getItem("update").events.on("click", async ()=>{
          //console.log('Update DisplayName', 'val-1:', formContainer.value.formInstance.getItem("newDN").getValue());
          //console.log('Update DisplayName', 'val-2:', window.document.querySelector(`.${uuid}`).querySelector("input").value);
          //let newDN = formContainer.value.formInstance.getItem("newDN").getValue() ||
          //window.document.querySelector(`.${uuid}`).querySelector("input").value;
          newDN = formContainer.value.formInstance.getItem("newDN").getValue() || newDN;

          //console.log('Update DisplayName', 'newDN:', newDN);
          if(newDN) {
            await oxygenUtil.callCommonServiceAPI('oxygen-components', 'updateDisplayName',
                Object.assign({
                  OxygenID: client.value.ClientRight
                }, DNs.includes(newDN) ?
                    { DisplayName: newDN} : {DisplayNamePrefix: newDN}));
            let newItem = await oxygenUtil.getComponent(client.value.ClientRight);
            window.devStore.updateRootMenu({ Path: rootMenuItem.Path, RightDN: newItem.DisplayName });
            //window.devStore.rootMenu.find(e => e.ViewPortID === props.viewportID).RightDN =
            rootMenuItem.RightDN = client.value.ClientRightDN = newItem.DisplayName;

            formContainer.value.hide();
          }


        });
      }

    })
  }

  function addSystemDesignerOption() {
    window.DevMgr.addRightMemu(props.viewportID, {
      icon: `fal fa-code`,
      title: 'Open System Designer',
      callback: async (rightMenuItem) => {


        let terminationUrl = {"MediaType":"Iframe","URL":`/SystemDesigner/index.html?${(new URLSearchParams({

              ContextPE: getContextPE,
              ClientID: props.ClientID,
              PanelID: props.viewportID
            }))}`};
        window.BotServices.openChildPanel({ClientID : props.ClientID, DisplayName : 'System Designer', Termination : terminationUrl}, ()=>{});




      }
    });
  }

  function addRoleOption() {
    window.DevMgr.addRightMemu(props.viewportID, {
      icon: `fal fa-share-nodes`,
      title: 'Add Role',
      callback: async (rightMenuItem)=> {
        formContainerTitle.value = 'New Role';
        formRows.value = [
          {
            type: "input",
            name: "roleName",
            label: "Role Name",
            value: ''
          },

          {
            type: "button",
            css: "spaced-button",
            name: "add",
            text: "Add",
            size: "medium",
            view: "flat",
            color: "primary"
          }
        ];

        formContainer.value.showContainerAlignedToElementInViewport(
            document.getElementById(props.viewportID).querySelector(".main-panel-header-title"),
            'bottom',
            document.getElementById(props.viewportID).getBoundingClientRect()
        );

        await nextTick();


        formContainer.value.formInstance.getItem("add").events.on("click", async ()=>{
          let roleName;
          //console.log('addRole-click', 'role:',  formContainer.value.formInstance.getItem("roleName").getValue());
          if(roleName = formContainer.value.formInstance.getItem("roleName").getValue()) {
            await oxygenUtil.putGrant({
              onObject: client.value.ClientRight,
              onRole: roleName,
              toPE: clientContext.value.ToPE,
              fromPE: clientContext.value.FromPE,
              fromGrantId: clientContext.value.FromGrantID,
              byPE: clientContext.value.GrantPE,
              byGrantId: clientContext.value.GrantID,
            });
            formContainer.value.hide();
          }
        });
      }

    })
  }

  async function showListNameForm(list) {
    formContainerTitle.value = 'List Name';
    formRows.value = [
      {
        type: "input",
        name: "listName",
        label: "List Name",
        value: ''
      },

      {
        type: "button",
        css: "spaced-button",
        name: "save",
        text: "Save",
        size: "medium",
        view: "flat",
        color: "primary"
      }
    ];

    formContainer.value.showContainerAlignedToElementInViewport(
        document.getElementById(props.viewportID).querySelector(".main-panel-header-title"),
        'bottom',
        document.getElementById(props.viewportID).getBoundingClientRect()
    );

    await nextTick();


    formContainer.value.formInstance.getItem("save").events.on("click", async ()=>{
      let listName;

      if(listName = formContainer.value.formInstance.getItem("listName").getValue()) {
        //console.log('showListNameForm', 'calling SetProviderData', ' listName:', listName, ' - list:', list);
        window.DevMgr.SetProviderData(
            {ClientId : client.value.Client, TemplateId : '_FFFFFFFFFFFFFF00001668203009117626_', Data : list, DisplayName : listName}, (err, res) => {
              //console.log('showListNameForm', 'called SetProviderData', ' err:', err, ' - res:', res);
              formContainer.value.hide();
            });


      }
    });
  }

  function addUploadContactsOption() {
    window.DevMgr.addRightMemu(props.viewportID, {
      icon: `fal fa-upload`,
      title: 'Contacts Upload',
      callback: async (rightMenuItem) => {
        if(client.value.childViewports && client.value.childViewports['showFiles']) {
          client.value.ignoreAttachmentCreation = true;
          let uploadId = oxygenUtil.getUUID();
          let uploadViewportId = client.value.childViewports['showFiles']();

          client.value.fileUploadCallbacks[uploadId] = async (uploadData) => {
            //console.log('addUploadContactsOption', 'fileUploadCallbacks', ' uploadData:', uploadData);
            let records = await getCSVData(uploadData.detail.filePath);
            //console.log('addUploadContactsOption', 'getCSVData', ' records:', records);
            client.value.ignoreAttachmentCreation = false;
            let responses = [];
            await async.each(records, async (record)=>{
              let response  = await addProviderSearchData(record);
              if(!response.error) {
                responses.push(response.result);
              }
              //console.log('addUploadContactsOption', 'addProviderSearchData', ' response:', response);
            });
            window.DevMgr.closeViewPort(uploadViewportId);
            await showListNameForm(responses);

          };

          //console.log('addUploadContactsOption', 'uploadViewportId:', uploadViewportId);
          await nextTick();
          await nextTick();
          await nextTick();
          let counter = 0;
          let intervalID = setInterval(()=>{
            let wc = document.querySelector(`iframe-container[data-viewportID="${uploadViewportId}"]`);
            //console.log('addUploadContactsOption', 'wc:', wc);
            if(counter > 10) {
              clearInterval(intervalID);
            }
            else if(wc && wc.shadowRoot && wc.shadowRoot.getElementById(wc.id) && wc.shadowRoot.getElementById(wc.id).contentWindow.uppy) {
              //console.log('addUploadContactsOption', 'uppy:', wc.shadowRoot.getElementById(wc.id).contentWindow.uppy);
              wc.shadowRoot.getElementById(wc.id).contentWindow.uppy.setOptions({
                restrictions: {
                  allowedFileTypes: ['.csv']
                }
              });
              wc.shadowRoot.getElementById(wc.id).contentWindow.uppy.on('upload-success', async (file, response) => {
                //console.log('upload-success', 'file:', file);
                //Call API to upload data to Master Provider
                //Do openPE on the list component template
              });
              clearInterval(intervalID);
            }
            else {
              counter++;
            }

          },200);
          return true;
        }
      }
    });
  }

  let schedulerContainerTitle = ref(''), schedulerContainer = ref(null), defaultViewArgs = ref([new Date(), 'week']);

  function addSetAvailability() {
    window.DevMgr.addRightMemu(props.viewportID, {
      icon: `fal fa-calendar`,
      title: 'Set Availability',
      callback: async (rightMenuItem) => {
        schedulerContainerTitle.value = 'Set Availability';

        schedulerContainer.value.showContainerAlignedToElementInViewport(
            document.getElementById(props.viewportID).querySelector(".main-panel-header-title"),
            'bottom',
            document.getElementById(props.viewportID).getBoundingClientRect()
        );

        await nextTick();
        await nextTick();
        await nextTick();
        let scheduler, attempts = 0;
        let interval = setInterval(async ()=>{
          //console.log('availability', 'calendar:', schedulerContainer.value.calendar);
          //console.log('availability', 'scheduler:', schedulerContainer.value.calendar._.data.mainScheduler);
          if(( attributionRecord && schedulerContainer.value.calendar && (scheduler = schedulerContainer.value.calendar._.data.mainScheduler)) || (attempts > 5)) {
            clearInterval(interval);
            let data = await oxygenUtil.callCommonServiceAPI('availability', 'get', { AttributionOID: attributionRecord.AttributionID });
            //console.log('availability', 'data:', data);
            let currentInterval = null, lastVal = 0;
            data.availability.split(" ").forEach((e, i, arr)=>{
              //console.log('availability', 'e:', e, ' - diff:', parseInt(e) - parseInt(lastVal), ' - t2:', parseInt(lastVal ) + 900000);
              if(!currentInterval) {
                currentInterval = { t1: parseInt(e)}
              }
              else {
                if(((parseInt(e) - parseInt(lastVal)) !== 900000 ) || (i === (arr.length - 1))  ) {
                  currentInterval.t2 = parseInt(lastVal ) + 900000;
                  scheduler.addEvent({ start_date: formatEventTime(currentInterval.t1),
                    end_date: formatEventTime(currentInterval.t2), text: 'Availability'});
                  currentInterval = null;
                }

              }
              lastVal = e;
            });

          }
          attempts++;
        },100);

      }
    });
  }

  function formatEventTime(timestamp) {
    //console.log('formatEventTime', 'timestamp:', timestamp);
    return new Date(timestamp).toISOString().replace('T', ' ').replace(/:..\..+$/,'');
  }



  function calendarEventCreated(data) {
    //console.log('calendarEventCreated', 'called - data:', data);

    //console.log('calendarEventCreated', 'schedulerContainer:', schedulerContainer.value);
    //console.log('calendarEventCreated', 'calendar:', schedulerContainer.value.calendar);
    //console.log('calendarEventCreated', 'scheduler:', schedulerContainer.value.calendar._.data.mainScheduler);
    let scheduler = schedulerContainer.value.calendar._.data.mainScheduler;

    scheduler.getEvent(data.id).text = "Available"; //changes event's data
    scheduler.updateEvent(data.id);   // renders the updated event
  }


  async function getCSVData(filePath) {
    let data = await oxygenUtil.callCommonServiceAPI('csv-file-parser','parseFile', { filePath });
    return data.records;
  }

  async function addProviderSearchData(data) {
    let url = `/ProviderSearch-0026/Server/directories/create-update`;

    let response = await fetch(
        url,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          mode: 'cors'
        }
    ).catch((e)=> {
      //console.log('addProviderSearchData', 'error:', e);
      throw e;
    });
    let responseObj;
    if(response.ok && (responseObj = await response.json())) {
      return responseObj;
    }
    return false;
  }

  function addSetProfileOption() {
    window.DevMgr.addRightMemu(props.viewportID, {
      icon: `fal fa-address-card`,
      title: 'Set Profile',
      callback: async (rightMenuItem)=> {
        /*
        let rows = await oxygenUtil.runDBQuery(
            `select * from OxygenDatabase.Attribution where CredentialPE = ? and AccountType = ?`,
            [ window.LivingScript_0070.context.credentialPE, 'PrimaryAccount' ]
        );

         */
        let rows;
        if(client.value.Attribution) {
          rows = await oxygenUtil.runDBQuery(
              `select * from OxygenDatabase.Attribution where ID = ?`,
              [ client.value.Attribution ]
          );
        }
        else {
          rows = await oxygenUtil.runDBQuery(
              `select * from OxygenDatabase.Attribution where CredentialPE = ? and AccountType = ?`,
              [ window.LivingScript_0070.context.credentialPE, 'PrimaryAccount' ]
          );
        }


        let existingSettings = {};
        if(rows.length) {
          existingSettings = rows[0];
        }

        //let isGateway = !(existingSettings.AccountType === "PrimaryAccount");
        //console.log('Set Profile', 'isGateway:', isGateway);

        let photoUrl =  existingSettings.picture;

        formContainerTitle.value = 'Set Profile';
        formRows.value = [
          {
            type: "input",
            name: "FirstName",
            label: "First Name",
            value:  existingSettings.given_name || '',
            padding: "10px"
          },
          {
            type: "input",
            name: "LastName",
            label: "Last Name",
            value: existingSettings.family_name || '',
            padding: "10px"
          },
          {
            type: "input",
            name: "Email",
            label: "Email",
            value:  existingSettings.email || '',
            padding: "10px",
          },
          {
            name: "Subject",
            type: "checkbox",
            text: "Subject Only",
            checked: false
          },
          {
            type: "input",
            name: "PhoneNumber",
            label: "Phone Number",
            value:  existingSettings.phone_number || '',
            padding: "10px",
          },
          {
            type: "textarea",
            name: "Address",
            label: "Address",
            value:  existingSettings.address || '',
            resizable: false,
            height: "135px",
            padding: "10px",
          },
          {
            type: "input",
            name: "Birthdate",
            label: "Birthdate",
            value: existingSettings.birthdate || '',
            placeholder: 'mm/dd/yyyy',
            padding: "10px"
          },
          {
            type: "select",
            name: "Gender",
            label: "Gender",
            value: existingSettings.gender || '',
            options: [
              { value: "", content: ""},
              { value: "male", content: "Male"},
              { value: "female", content: "Female"},
              { value: "other", content: "Other"},
            ],
            padding: "10px"
          },
          {
            type: "textarea",
            name: "Website",
            label: "Website",
            value:  existingSettings.website || '',
            resizable: false,
            height: "135px",
            padding: "10px",
          },
          {
            align: "start",
            cols: [
              {
                type: "button",
                css: "spaced-button",
                name: "uploadPhoto",
                text: "Upload Profile Photo",
                size: "medium",
                view: "flat",
                color: "primary",
                padding: "10px"
              },
              {
                type: "container",
                name: "upload-progress",
                padding: "10px",
                html: `<div></div>`
              }
            ]
          },
          {
            type: "button",
            css: "spaced-button",
            name: "save",
            text: "Save",
            size: "medium",
            view: "flat",
            color: "primary",
            padding: "10px"
          }
        ];

        formContainer.value.showContainerAlignedToElementInViewport(
            document.getElementById(props.viewportID).querySelector(".main-panel-header-title"),
            'bottom',
            document.getElementById(props.viewportID).getBoundingClientRect()
        );

        await nextTick();

        window._formContainer = formContainer.value;

        formContainer.value.formInstance.getItem("uploadPhoto").events.on("click", async ()=>{
          let contextPE = oxygenUtil.isCredentialAccount(client.value) ?
              ( oxygenUtil.createSimpleOES(['admin', client.value.ClientRight, 'admin', client.value.ClientRight]) ):
              oxygenUtil.createSimpleOES(
                  [client.value.ClientLeftRole, client.value.ClientLeft, client.value.ClientRightRole, client.value.ClientRight]
              );
          let iframeViewportID = oxygenUtil.showIframeViewport(
              props.ClientID,
              oxygenUtil.getMainViewportID(client.value.Meeting),
              'UploadPhoto',
              'Upload Photo',
              `/OxygenFileServices-0070/s3.html?${(new URLSearchParams({
                bucket: 'n.simplia.com',
                ContextPE: contextPE,
                ClientID: props.ClientID,
                PanelID: props.viewportID}))}`
          );
          await nextTick();
          await nextTick();
          await nextTick();
          let counter = 0;
          let intervalID = setInterval(()=>{
            let wc = document.querySelector(`iframe-container[data-viewportID="${iframeViewportID}"]`);
            if(counter > 10) {
              clearInterval(intervalID);
            }
            else if(wc && wc.shadowRoot && wc.shadowRoot.getElementById(wc.id) && wc.shadowRoot.getElementById(wc.id).contentWindow.uppy) {
              let fileid = oxygenUtil.getGUID();
              wc.shadowRoot.getElementById(wc.id).contentWindow.uppy.setOptions({
                onBeforeFileAdded: (currentFile, files) => {
                  console.log('onBeforeFileAdded', 'currentFile:', currentFile);
                  let fileExt = /^.+\.(.+)$/.exec(currentFile.name)[1];
                  const modifiedFile = {
                    ...currentFile,
                    name: `${fileid}.${fileExt}`
                  }
                  console.log('onBeforeFileAdded', 'modifiedFile:', modifiedFile);

                  return modifiedFile
                }
              });

              wc.shadowRoot.getElementById(wc.id).contentWindow.uppy.on('upload-success', (file, response) => {
                console.log('upload-success', 'file:', file);
                let parsedPE = oxygenUtil.parseOES(contextPE).segments
                photoUrl = `https://s3.amazonaws.com//n.simplia.com/${parsedPE[1]}/${parsedPE[3]}/${parsedPE[0]}/${parsedPE[2]}/${file.name}`;
                window.DevMgr.closeViewPort(iframeViewportID);
                formContainer.value.formInstance.getItem("upload-progress").attachHTML('<span style="color: green; font-size:24px;" class="fal fa-badge-check"></span>')
              });
              clearInterval(intervalID);
            }
            else {
              counter++;
            }

          },200);


        });

        formContainer.value.formInstance.getItem("save").events.on("click", async ()=>{
          await oxygenUtil.runDBQuery(

                  `update OxygenDatabase.Attribution set
given_name = ?,
family_name = ?,
picture = ?,
email = ?,
phone_number = ?,
address = ?,
birthdate = ?,
gender = ?
where ID = ?`,
              [
                  formContainer.value.formInstance.getItem("FirstName").getValue(),
                formContainer.value.formInstance.getItem("LastName").getValue(),
                photoUrl,
                formContainer.value.formInstance.getItem("Email").getValue(),
                formContainer.value.formInstance.getItem("PhoneNumber").getValue(),
                formContainer.value.formInstance.getItem("Address").getValue(),
                formContainer.value.formInstance.getItem("Birthdate").getValue(),
                formContainer.value.formInstance.getItem("Gender").getValue(),
                existingSettings.ID]
          );

          let parsedContextPE = oxygenUtil.parseOES(getContextPE()).segments;
          let subjectPE = oxygenUtil.createSimpleOES([parsedContextPE[2],parsedContextPE[3],parsedContextPE[2],parsedContextPE[3]]);
          if(!rows.length || existingSettings[`email`] !== formContainer.value.formInstance.getItem("Email").getValue() || formContainer.value.formInstance.getItem("Subject").getValue() ) {
            await oxygenUtil.callCommonServiceAPI(
                "simplia-api",
                'addTrackingRecord',
                {
                  relation: 'EmailToPE',
                  PE1: formContainer.value.formInstance.getItem("Email").getValue(),
                  PE2: formContainer.value.formInstance.getItem("Subject").getValue() ? subjectPE : getContextPE(),
                  extraParams: {
                  }
                }
            );
          }


          formContainer.value.hide();
        });
      }

    });
  }

  async function addRightMenuOptions() {
    addSavePDFOption();
    //addEmailSettingsOption();

    let adminRoles = ['admin', 'owner', 'holder'];
    if(adminRoles.includes(client.value.ClientRightRole)) {
      addRoleOption();
      addSystemDesignerOption();
      addChangeDNOption();
      addEditContextOption();
      addSetAvailabilityOption();
      addChooseAppointmentOption();
      //addRoleTokenOption();
    }
    console.log('addRightMenuOptions', 'adding addShowAppointmentsOptions');
    addShowAppointmentsOption();

    addSetProfileOption();

    addUploadContactsOption();

    let viewportContext = oxygenUtil.getViewportContext(props.viewportID);
    let clientRight = await oxygenUtil.getComponent(client.value.ClientRight);
    //console.log('addRightMenuOptions', 'clientRight:', clientRight, ' - includes:', clientRight.FFTTID.includes("2016-11-08T21:52:39.289240Z000-0000Z"));
    if(clientRight && clientRight.FFTTID && clientRight.FFTTID.includes("2016-11-08T21:52:39.289240Z000-0000Z")) {
      addDownloadFileOption();
    }

    //addSetAvailability();
  }

  function getContextPE() {
    return (window.LivingScript_0070.context.credentialId  === client.value.ClientRight) ?
            ( oxygenUtil.createSimpleOES(['admin', client.value.ClientRight, 'admin', client.value.ClientRight]) ):
            oxygenUtil.createSimpleOES([client.value.ClientLeftRole, client.value.ClientLeft, client.value.ClientRightRole, client.value.ClientRight]);
  }

  async function makePDF(namePrefix, fullHeight) {
    //console.log('makePDF', 'fullHeight:', fullHeight);
    let url = `/OxygenFileServices-0070/companion/make-pdf`;

    let data = {
      "content": oxygenUtil.getViewportHTML(props.viewportID, fullHeight), // HTML content
      "name": namePrefix, // Provide GDN of the PDF if Grant needed
      "storage": "grant", // Option - pdf: if direct pdf is needed, s3: if s3 link, grant: create grants
      "toPE": getContextPE()// provide if grant needed otherwise optional
    };


    let response = await fetch(
        url,
        {
          method: 'POST',
          //body: new URLSearchParams(data),
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          mode: 'cors'
        }
    ).catch((e)=> {
      throw e;
    });

    let responseObj;
    if(response.ok && (responseObj = await response.json())) {
      return responseObj.data;
    }
    return false;
  }

  async function postEmailPDFHistoryTask() {
    let historyItem = await oxygenUtil.postHistoryMessage(Object.assign({
      PK: oxygenUtil.getGUID(),
      FromPE: this.GatewayPE,
      ToPE: this.dataRow.data.ByPE,
      ByPE: this.envelopeByPE ,
      CredentialPE: this.CredentialPE,
      AgentOfPE: this.AgentOfPE,
      GatewayPE: this.GatewayPE,
      //TransactionId: this.lastTransactionId,
      CausingEventID: this.dataRow.data.PK,
      ArgJSON: JSON.stringify(this.createAgreementArgJSON()),
      Note: this.dataRow.data.Note,
      EventLabel: this.dataRow.data.EventLabel,
      EventTemplate: "_FFFFFFFFFFFFFF00001664230222490180_",
      NoteTemplate: "MessageNoteTemplate-0056",
      NoteTemplateID: "_FFFFFFFFFFFFFF00001633127893643639_",
      TaskTemplateID: '_FF10313609901216306869076411514878_',
      FCX: {

      }
    },( this.geolocation && this.geolocation.location) ? {
      Lat: this.geolocation.location.latitude,
      Long: this.geolocation.location.longitude,
      IPAddress: this.geolocation.traits.ip_address,
      City: this.geolocation.city.names.en,
      State: this.geolocation.postal.code,
      Country: this.geolocation.country.names.en,
      GeoIPLocation: JSON.stringify(this.geolocation)
    } : {}));
  }

  let formContainerTitle = ref(''), formRows = ref([]), formContainer = ref(null);

  /**
   * Toggling Ribbon
   */
  let toggleRibbonTooltips = { 'show': 'Show Options', 'hide': 'Hide Options' }, toggleRibbonTooltip = ref(toggleRibbonTooltips.show), lastHeaderRibbonState = false;
  function toggleRibbon() {
    lastHeaderRibbonState = showHeaderRibbon.value = !showHeaderRibbon.value;
    if(showHeaderRibbon.value) {
      toggleRibbonTooltip.value = toggleRibbonTooltips.hide;
    }
    else {
      toggleRibbonTooltip.value = toggleRibbonTooltips.show;
    }
  }

  async function showUISettings() {
    formContainerTitle.value = 'UI Settings';
    formRows.value = [
      {
        type: "select",
        name: "template",
        label: "Template",

        options:[
          { value: '_FF10313609901216306869076411514878_', content: 'PlateTestTemplate'}
        ],
        value: '_FF10313609901216306869076411514878_'
      },
      {
        id: "documentHeight",
        name: "documentHeight",
        type: "radioGroup",
        required: true,
        labelPosition: "top",
        value: "entire",
        options: {
          rows: [
            {
              type: "radioButton",
              text: "Entire Document",
              value: "entire",
            },
            {
              type: "radioButton",
              text: "Visible Document",
              value: "visible"
            },
          ]
        }
      },
      {
        type: "input",
        name: "saveAsName",
        label: "Save As",
        value: client.value.ClientRightDN
      },
      /*{
        type: "input",
        label: 'Email',
        name: 'email'
      },*/
      {
        type: "button",
        css: "spaced-button",
        name: "saveAsPDF",
        text: "Save As PDF",
        size: "medium",
        view: "flat",
        color: "primary"
      }
    ];

    formContainer.value.showContainerAlignedToElementInViewport(
        document.getElementById(props.viewportID).querySelector(".main-panel-header-title"),
        'bottom',
        document.getElementById(props.viewportID).getBoundingClientRect()
    );

    await nextTick();
    //console.log('saveAsPDF-callback', 'formContainer:', formContainer);
    window._formContainer = formContainer.value;
    formContainer.value.formInstance.getItem("saveAsPDF").events.on("click", async ()=>{
      //formInstance.getItem("email").getValue()
      //console.log('saveAsPDF-callback');
      //await postEmailPDFHistoryTask();
      let component = await makePDF(formContainer.value.formInstance.getItem("saveAsName").getValue(), formContainer.value.formInstance.getItem("documentHeight").getValue() == "entire");
      formContainer.value.hide();
      window.BotServices.PullPE(
          {
            ByClientID : client.value.Client,
            InitialContext : {
              ByCLientID: client.value.Client
            },
            OpenPE : component.grants.adminGrant.GrantPE
          }
      );
    });
  }

  defineExpose({
  });
</script>


<style>
@import url('/simplia/dist/css/all.min.css');
@import url('/simplia/dist/css/floating-vue-style.css');

div:focus {
  outline: 0;
}

.reduce-font-icon{
  font-size: 75%
}

.enlarge-font-icon {
  font-size: 125%
}

.main-panel-header-title {
  height: 39px;
  color: rgb(0, 114, 197);
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-family: Roboto,Arial,Tahoma,Verdana,sans-serif!important;
}

.header-left-container {
  flex-grow: 1;
  display:inline-flex;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
}

.oxygen-home-icon {
  cursor: pointer;
  /*padding-top: 4px;*/
  padding-left: 5px;
  padding-right: 5px;
  font-size: 1em;
}

.simplia-panel-title{
  min-width: 0;
  display: inline-flex;
}

.panel-index {
  cursor: pointer;
}

.thread-subject-title {
  overflow: hidden;
  display: inline-flex;
  align-items: center;
}

.title-thread-role,
.title-subject-role {
  cursor: pointer;
  padding-right: 5px;
}

.title-component {
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.rightmenu-container {
  flex-basis: 0;
  display: inline-flex;
  flex-direction: row-reverse;
  font-size: 1em;
}

.screen-size-icon{
  padding-left:10px;
  padding-right:10px;
  cursor: pointer;
}

.screen-size-icon-first {
  padding-right: 5px
}

.vfm__container.vfm--absolute.vfm--inset.vfm--outline-none.modal-container {
  z-index: 1000;
}

</style>

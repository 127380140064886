<template>
  <child-container
      :container-name="containerName"
      :container-title="title"
      :width="width"
      :show-title="showTitle"
      ref="container"
      @container-hidden="containerHidden"
  >

    <div ref="mainContainer" style="overflow:auto;" :style="{'max-height': containerMaxHeight}">
      <oxygen-menu-table
          :row-style="rowStyle"
          ref="menuTable"
          class="options-table"
          :tableData="tableData"
          :hide-checkbox-col="!showCheckbox"
          :hide-folder-col="!showFolder"
          @row-selected="rowSelected"
          @row-deselected="rowDeselected"
      ></oxygen-menu-table>
    </div>
  </child-container>

</template>

<script setup>
import { reactive, onMounted, watch, ref, nextTick } from 'vue';
import ChildContainer from './../ChildContainer/ChildContainer.ce.vue';
import OxygenMenuTable from './../OxygenMenuTable/OxygenMenuTable.ce.vue';

import * as oxygenUtil from './../OxygenUtil/oxygenutil.js';

const props = defineProps({
  containerHidden: {
    type: Function
  },
  rightAligned: {
    type: Boolean,
    default: false
  },
  fontSize: {
    type: String,
    default: ''
  },
  showTitle: {
    type: Boolean,
    default: true
  },
  linkedRibbon: {
    type: Object,
    default: null
  },
  initialLimit: {
    type: Number,
    default: 4
  },
  title: String,
  options: {
    type: Array,
    default: []
  },
  containerName: String,
  width: {
    type: String,
    default: '250px'
  },
  tableParams: {
    type: Object,
    default: {}
  },
  hideFolderCol: {
    type: Boolean,
    default: false
  },
  hideCheckboxCol: {
    type: Boolean,
    default: false
  },
  showRightMenu: {
    type: Boolean,
    default: false
  },
  showCheckbox: {
    type: Boolean,
    default: true
  },
  showFolder: {
    type: Boolean,
    default: true
  }
});

let rowStyle = ref({
  'line-height': '40px'
})

let emit = defineEmits(['row-selected', 'row-deselected']);

function rowSelected(...args) {
  let dataRow = args[0], index = args[1];
  if(dataRow.checkboxSync) {
    dataRow.data.callback(dataRow.data, dataRow, index, menuTable.value);
    if(dataRow.hideMenu) {
      hideMenu();
    }
  }
  if(props.linkedRibbon) {
    showRibbonIcon(...args);
  }
  emit('row-selected', ...args);
}

function rowDeselected(...args) {
  if(props.linkedRibbon) {
    hideRibbonIcon(...args);
  }
  emit('row-deselected', ...args);
}

function showRibbonIcon(dataRow, index) {
  if(!props.linkedRibbon.getIcon(dataRow.data.id)) {

    props.linkedRibbon.addRibbonIcons([{
      id: dataRow.data.id,
      iconInfo: { type: "css", classes: dataRow.data.icon },
      callback: ()=>{
        dataRow.data.callback();
      },
      orientation: 'left',
      toolTip: dataRow.data.title
    }]);
  }
  else {
    props.linkedRibbon.showRibbonIcon(dataRow.data.id);
  }
}

function hideRibbonIcon(dataRow, index) {
  props.linkedRibbon.hideRibbonIcon(dataRow.data.id);
}


const menuTable = ref(null), container = ref(null);

let defaultTableData = {
  showHeader: false,
  showCheckbox: props.showCheckbox,
  showFolder: props.showFolder,
  showRightMenu: props.showRightMenu,
  cols: [
    {
      callback: () => {
      },
      header: 'Icon',
      type: 'icon'
    },
    {
      callback: () => {
      },
      header: 'Title',
      type: 'html'
    }
  ]
};

let tableData = ref(Object.assign({},defaultTableData, props.tableParams));

onMounted(()=>{
  //console.log('optionsMenu-mounted', 'called');
  putOptionsInMenu();
});

watch(
    ()=> props.tableParams,
    () => {
      //console.log('watch-tableParams', 'tableParams:', props.tableParams);
      tableData.value = Object.assign({}, defaultTableData, props.tableParams);
    },
    {
      deep: true
    }
);

let showAll = ref(false);

watch(
    ()=> props.options,
    ()=>{
      //console.log('watch-options:', props.options);
      menuTable.value.resetTable();
      putOptionsInMenu();
    },
    {
      deep: true
    }
);


async function putOptionsInMenu() {
  //console.log('putOptionsInMenu', 'options:', props.options);
  props.options.forEach((option, index)=> {
    addOptionRow(option);
  });
  await nextTick();
  await nextTick();
  containerMaxHeight.value = calcContainerMaxHeight();
}

let optionDataRowPath = '';

function addOptionRow(option) {
  let numRows = menuTable.value.tableData.dataRows.length;
  let iconCol = {
    html: (typeof option.icon === "object") ? option.icon.html : `<span class="${option.icon || ''}"></span>`,
    tooltip: option.tooltip || '',
    callback: (dataRow, index)=>{
      if(option.iconCallback) {
        option.iconCallback(dataRow.data, dataRow, index, menuTable.value);
      }
      else {
        option.callback(dataRow.data, dataRow, index, menuTable.value);
        if(dataRow.hideMenu) {
          hideMenu();
        }
      }

    }
  };
  let textCol = {
    html: `<span title="${option.title}" style="padding-right:10px;font-size:${props.fontSize || oxygenUtil.config.fontSize}">${option.title}</span>`,
    tooltip: option.tooltip || '',
    styleObj: props.rightAligned ? { 'text-align': 'right'} : {},
    callback: (dataRow, index)=>{
      option.callback(dataRow.data, dataRow, index, menuTable.value);
      if(dataRow.hideMenu) {
        hideMenu();
      }
    }
  };

  let cols = [];
  if(props.rightAligned) {
    cols.push(textCol, iconCol);
  }
  else {
    cols.push(iconCol, textCol);
  }

  menuTable.value.addRow(
      Object.assign({
        cols,
        data: option,
        path: `${option.path}`,
        rightMenuCallback: option.hasOwnProperty('rightMenuCallback') ? option.rightMenuCallback : ()=>{},
        showRightMenu: option.hasOwnProperty('showRightMenu') ? option.showRightMenu : false,
        hideMenu: option.hasOwnProperty('hideMenu') ? option.hideMenu : true,
        showFolder: option.hasOwnProperty('showFolder') ? option.showFolder: false,
        checkboxSync: option.hasOwnProperty('checkboxSync') ? option.checkboxSync: false,
        show:  (numRows > props.initialLimit) ? false : (
            option.hasOwnProperty('show') ? option.show : ( (option.path.includes('/')) ? false : true)),
        tempShowVal: option.hasOwnProperty('show') ? option.show : ( (option.path.includes('/')) ? false : true),
        tempHidden: numRows > props.initialLimit
      }, option.folderColCB ? { folderCol: { callback: option.folderColCB } } : {}, option.menuParams || {})
  );

  if(numRows === props.initialLimit) {
    let optionDataRowPath = `${(numRows + '').padStart(3,'0')}`;
    menuTable.value.addRow({
      cols: [
        {
          html: `<span class="fal fa-ellipsis"></span>`,
          callback: (dataRow)=>{
            showAllOptions(dataRow)
          }
        },
        {
          html: `<span title="Show More" style="padding-right:10px;font-size:${oxygenUtil.config.fontSize}">Show More</span>`,
          callback: (dataRow)=>{
            showAllOptions(dataRow);
          }
        }
      ],
      path: optionDataRowPath
    });


    menuTable.value.addRow({
      cols: [
        {
          html: `<span class="fal fa-ellipsis"></span>`,
          callback: (dataRow)=>{
            showLessOptions()
          }
        },
        {
          html: `<span title="Show More" style="padding-right:10px;font-size:${oxygenUtil.config.fontSize}">Show Less</span>`,
          callback: (dataRow)=>{
            showLessOptions();
          }
        }
      ],
      path: `999999`,
      show: false,
      tempHidden: true
    });
  }
}

let _optionDataRow;
function showAllOptions(optionDataRow) {
  _optionDataRow = optionDataRow;
  optionDataRow.show = false;
  menuTable.value.tableData.dataRows.forEach((e)=>{
    if(e.tempHidden && ((typeof e.tempShowVal !== "undefined") ? e.tempShowVal : true )) {
      e.show = true;
    }
  })
}

function showLessOptions() {
  //menuTable.value.getRow(optionDataRowPath).show = true;
  _optionDataRow.show = true;

  menuTable.value.tableData.dataRows.forEach((e)=>{
    if(e.tempHidden && e.show) {
      e.show = false;
    }
    e.expanded = false;
  });


}

async function showMenuAlignedToElement(...args) {
  container.value.showModalAlignedToElement(...args)
  await nextTick();
  await nextTick();
  containerMaxHeight.value = calcContainerMaxHeight();
}

function showMenu() {
  container.value.show();
}

function hideMenu() {
  container.value.hide();
}

function emptyMenu() {
  menuTable.value.resetTable();
}

/*
* Container Height Adjustment
*
* */
let containerMaxHeight = ref(''), mainContainer = ref(null);

function calcContainerMaxHeight() {
  if(mainContainer.value) {
    let rect = mainContainer.value.getBoundingClientRect();
    return `${window.innerHeight - rect.y - 1}px`;
  }
  return `${window.innerHeight}px`;
}

addEventListener("resize", (event) => {
  containerMaxHeight.value = calcContainerMaxHeight();
});

defineExpose({
  showMenu,
  hideMenu,
  showMenuAlignedToElement,
  options: props.options,
  emptyMenu,
  tableData,
  menuTable,
  addOptionRow
});

</script>

<style scoped>
.options-table {
  color: black;
}

</style>
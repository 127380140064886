<template>
  <div class="header-title-ribbon" v-show="showRibbon" ref="mainDiv" :style="mainDivStyle" >
    <span class="ribbon-left" ref="leftRibbon">
      <template v-for="(item, index) in leftRibbonItems" ref="leftItemRefs">
        <span v-if="item.iconInfo.type === 'css'"
              :key="item.id"
              @click="ribbonItemClick(item.id, $event)"
              :id="iconIdPrefix + item.id"

              v-tooltip="{content: item.tooltip || ''}"
              :class="item.classObject"
              class="ribbon-icon ribbon-icon-right"
              :style="item.styleObject"
              v-show="item.show"></span>
        <span v-else-if="item.iconInfo.type === 'html'" v-html="item.iconInfo.html"
              :key="item.id"
              @click="ribbonItemClick(item.id, $event)"
              :id="iconIdPrefix + item.id"
              :class="item.classObject"

              v-tooltip="{content: item.tooltip || ''}"
              class="ribbon-icon ribbon-icon-right flex-display"
              :style="item.styleObject"
              v-show="item.show"></span>
      </template>
    </span>
    <span class="ribbon-right" ref="rightRibbon" :style="rightRibbonStyle">
      <template v-for="(item, index) in rightRibbonItems" ref="rightItemRefs">
        <span v-if="item.iconInfo.type === 'css'"
              :key="item.id"

              @click="ribbonItemClick(item.id, $event)"
              :id="iconIdPrefix + item.id"
              v-tooltip="{content: item.tooltip || ''}"
              class="ribbon-icon ribbon-icon-right"
              :class="[{'ribbon-icon-right-leftmost': !index}], item.classObject"
              :style="item.styleObject"
              v-show="item.show"></span>
        <span v-else-if="item.iconInfo.type === 'html'" v-html="item.iconInfo.html"
              :key="item.id"
              @click="ribbonItemClick(item.id, $event)"
              :id="iconIdPrefix + item.id"
              :class="[{'ribbon-icon-right-leftmost': !index}], item.classObject"
              v-tooltip="{content: item.tooltip || ''}"
              class="ribbon-icon ribbon-icon-right flex-display"
              :style="item.styleObject"
              v-show="item.show"></span>
      </template>
    </span>

  </div>
</template>

<script setup>
  import { ref, reactive, computed, getCurrentInstance, onMounted } from 'vue';
  import * as oxygenUtil from './../OxygenUtil/oxygenutil.js';
  import { vTooltip } from 'floating-vue';



  const showRibbon = ref(true), leftRibbonItems = reactive([]), rightRibbonItems = reactive([]), allIcons = reactive({}),
      leftItemRefs = ref([]), rightItemRefs = ref([]);

  const props = defineProps({
    rightRibbonWrap: {
      type: String,
      default: 'nowrap'
    },
    rightRibbonPaddingRight: {
      type: String,
      default: '0'
    },
    allIconsStyle: {
      type: Object,
      default: {
        color: oxygenUtil.defaultIconColor
      }
    }
  });

  let ribbonItemClick = (itemId, ...args) => {
    //console.log('ribbonItemClick', 'itemId:', itemId);
    let item = allIcons[itemId];
    //console.log('ribbonItemClick', 'item:', item);
    item.callback(item, ...args);
  };

  let buttonPressed = (item, event) => {
    event.target.classList.add('clicked-button');
  };

  let buttonReleased = (item, event) => {
    event.target.classList.remove('clicked-button');
  };

  let getUUID = (uuidFormat) => {
    uuidFormat = uuidFormat || false;
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
    }

    if (uuidFormat) {
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
    else {
      return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
    }
  };

  const iconIdPrefix = `id${getUUID()}`;
  const rightRibbonStyle = computed(()=>{
    return {
      'flex-wrap': props.rightRibbonWrap,
      'padding-right':props.rightRibbonPaddingRight
    };
  });


  function addLeftRibbonIcon(...args) {
    //this.parent.debugLog('addLeftRibbonIcon', 'arguments:', arguments);
    //console.log('addLeftRibbonIcon','args:', args);


    if( (typeof args[4] === "undefined") || (args[4] === null) ) {

      args[4] = true;
    }
    if( (typeof args[5] === "undefined") || (args[5] === null) ) {
      args[5] = leftRibbonItems.length;
    }
    args.unshift(leftRibbonItems);

    addRibbonIcon(...args);
  }

  function addRightRibbonIcon(...args) {
    //console.log('addRightRibbonIcon','args:', args);
    //arguments[5] = ( (typeof arguments[5] !== "undefined") && (arguments[5] !== null) ) ? arguments[6] : 0;


    if( (typeof args[4] === "undefined") || (args[4] === null) ) {
      //args.push(true);
      args[4] = true;
    }
    if( (typeof args[5] === "undefined") || (args[5] === null) ) {
      //args.push(0)
      args[5] = rightRibbonItems.length;
    }

    args.unshift(rightRibbonItems);
    addRibbonIcon(...args);
  }

  function addRibbonIcons(ribbonIcons) {
    window.oxygenRibbon = currentInstance;
    ribbonIcons.forEach((icon)=>{
      let orientationFn = {
        'left': 'addLeftRibbonIcon',
        'right': 'addRightRibbonIcon'
      }

      if(orientationFn.hasOwnProperty(icon.orientation)) {
        //console.log('orientFn:', orientationFn[icon.orientation]);
        currentInstance.value.exposed[orientationFn[icon.orientation]](
            icon.id,
            icon.iconInfo,
            icon.callback,
            icon.toolTip,
            icon.hasOwnProperty('show') ? icon.show : true,
            icon.hasOwnProperty('position') ? icon.position : null,
            icon.hasOwnProperty('connected') ? icon.connected : true
        );
      }
    })
  }


  function addRibbonIcon (itemsArray, id, iconInfo, callback, tooltip, show, position, connected) {
    show = (typeof show !== "undefined") ? show : true;
    let icon = {
      id,
      callback,
      tooltip,
      tooltipShow: false,
      show,
      classObject: Object.assign({
        'gray-out': false,
        'blinking': false,
        'bold-icon': false
      }, iconInfo.type === 'css' ? iconInfo.classes.split(' ').reduce((p, c)=>{ p[c] = true; return p}, {}) : {}),
      styleObject: Object.assign({}, props.allIconsStyle, iconInfo.color ? { color: iconInfo.color} : {}, iconInfo.style || {} ),
      disabled: false,
      position: position,
      iconInfo,
      connected: (typeof connected !== "undefined") ? connected : true
    };

    allIcons[id] = icon;

    itemsArray.splice(position, 0, icon);
    //this.parent.debugLog('addRibbonIcon', 'itemsArray:', itemsArray);
    /*
    if(iconInfo) {
      //this.parent.debugLog('addRibbonIcon', 'selected:', iconInfo.selected);
      if (iconInfo.selected) {
        this.selectIcon(id);
      }
      if(iconInfo.disabled) {
        this.disableRibbonIcon(id);
      }
      let connectedIcon;
      if(iconInfo.connected && this.parent && this.parent.parentPanel.isChildPanel() && (connectedIcon = this.parent.parentPanel.parent.windowHeader.ribbon.findRibbonIcon(id))) {

        this.$nextTick(function(){
          this.changeIcon(id, connectedIcon.elementInfo,false);
          this.setRibbonIconColor(id, connectedIcon.styleObject.color, false);
          this.setRibbonIconShowValue(id, connectedIcon.show, false);

        });
      }
    }

     */
  }

  function getIconElement(id) {
    //window.currentRibbon = currentInstance;
    //currentInstance.value = getCurrentInstance();
    //return currentInstance.value.refs[id][0];
    //console.log('getIconElement', 'left:', leftItemRefs, ' - right:', rightItemRefs);
    //console.log('getIconElement', 'id:', id, ' - leftR:', leftRibbon.value, ' - rightR:', rightRibbon.value);
    //console.log('getIconElement', 'mainLQ:', leftRibbon.value.querySelector(`span[id="${iconIdPrefix}${id}"]`));
    //console.log('getIconElement', 'mainRQ:', rightRibbon.value.querySelector(`span[id="${iconIdPrefix}${id}"]`));
    //console.log('getIconElement', 'spanLQ', leftRibbon.value.querySelector(`span`));
    return leftRibbon.value.querySelector(`span[id="${iconIdPrefix}${id}"]`) ||
        rightRibbon.value.querySelector(`span[id="${iconIdPrefix}${id}"]`)
  }

  const currentInstance = ref(null)
  onMounted(()=>{
    currentInstance.value = getCurrentInstance();
  });

  function updateIconInfo(id, iconInfo) {
    let icon;
    if(icon = allIcons[id]) {
      icon.iconInfo = Object.assign(icon.iconInfo, iconInfo);
      icon.classObject = Object.assign({},{
        'gray-out': icon.classObject['gray-out'],
        'blinking': icon.classObject['blinking'],
        'bold-icon': icon.classObject['bold-icon']
      }, iconInfo.type === 'css' ? iconInfo.classes.split(' ').reduce((p, c)=>{ p[c] = true; return p}, {}) : {});

      if(icon.connected && window.devStore) {

      }
    }
  }

  function updateIconAttributes(id, attrs) {
    let icon;
    if (icon = allIcons[id]) {
      Object.entries(attrs).forEach((e)=>{
        if(icon[e[0]]) {
          icon[e[0]] = e[1];
        }
      });
    }
  }

  function updateIconCallback(id, callbackFn) {
    let icon;
    if(icon = allIcons[id]) {
      icon.callback = callbackFn
    }
  }

  function hideRibbonIcon(id) {
    setRibbonIconShowValue(id, false);
  }

  function showRibbonIcon(id) {
    setRibbonIconShowValue(id, true);
  }

  function setRibbonIconShowValue(id, value, connectedFlag) {
    connectedFlag = (typeof connectedFlag !== "undefined") ? connectedFlag : false;
    let icon;
    if(icon = allIcons[id]) {
      icon.show = value;
      if(icon.iconInfo && icon.iconInfo.connected && connectedFlag) {
        //this.callConnectedIconsFn('setRibbonIconShowValue', id, value, false);
      }
    }
  }

  let standardRowHeight = '39px';
  const leftRibbon = ref(null), rightRibbon = ref(null), mainDiv = ref(null), mainDivStyle = reactive({
    height: standardRowHeight
  });

  function expandRibbon() {
    //console.log('expandRibbon', 'left:', leftRibbonItems, ' - length:', leftRibbonItems.length);
    let totalIconsWidth = leftRibbonItems.filter(e => e.show).reduce((acc, cur)=>{
      //this.debugLog('expandRibbon', 'icon:', cur.id, ' - iconWidth:', $(this.windowHeader.ribbon.getRibbonIconElement(cur.id)).innerWidth());
      return acc += getIconElement(cur.id).clientWidth;//$(this.getRibbonIconElement(cur.id)).innerWidth();
    },0);
    //console.log('expandRibbon', 'totalIconsWidth:', totalIconsWidth);
    //this.debugLog('expandRibbon', 'ribbonWidth:', $(this.windowHeader.ribbon.mainElement).find('.ribbon-left').innerWidth());

    let numOfExtraRows = Math.ceil(totalIconsWidth / leftRibbon.value.clientWidth);//$(this.mainElement).find('.ribbon-left').innerWidth());
    //console.log('numOfExtraRows:', numOfExtraRows, ' - newHeight:',`${(parseInt(standardRowHeight) * numOfExtraRows)}px`);

    //this.debugLog('expandRibbon', 'numOfExtraRows:', numOfExtraRows);
    //$(this.mainElement).css('height', (parseInt(this.cssConstants.standardRowHeight) * numOfExtraRows));
    mainDivStyle.height = `${(parseInt(standardRowHeight) * numOfExtraRows)}px`;
  }

  function collapseRibbon() {
    //$(this.mainElement).css('height', this.cssConstants.standardRowHeight);
    mainDivStyle.height = standardRowHeight
  }

  function changeRibbonIconColor(id, color) {
    let icon;
    if(icon = allIcons[id]) {
      icon.styleObject.color = color;
    }
  }

  function getIcon(id) {
    return allIcons[id];
  }

  defineExpose({
    updateIconAttributes,
    addRibbonIcons,
    addRightRibbonIcon,
    addLeftRibbonIcon,
    leftRibbonItems,
    rightRibbonItems,
    allIcons,
    getIconElement,
    updateIconInfo,
    updateIconCallback,
    hideRibbonIcon,
    showRibbonIcon,
    expandRibbon,
    collapseRibbon,
    currentInstance,
    changeRibbonIconColor,
    getIcon
  });

</script>

<style scoped>
@import 'node_modules/floating-vue/dist/style.css';
/*@import url('/simplia/dist/css/floating-vue-style.css');*/

.header-title-ribbon { background: white; color: #0072C5; display: flex; /*height: 21px;*/  }
.ribbon-left {
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
  flex-grow: 1;
}

.ribbon-icon { cursor: pointer; }
.ribbon-icon-left { padding: 9px 12px; }
.ribbon-icon-right { padding: 9px 12px; }
.ribbon-icon-right-leftmost {padding-left: 6px;  }

.clicked-button {
  color: red;
}

.ribbon-right {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  flex-grow: 2;
}

.grayout {
  opacity: 0.6; /* Real browsers */
  filter: alpha(opacity = 60); /* MSIE */
  cursor: default;
}

.blinking {
  animation: blinker 1s linear infinite;
}

.bold-icon {
  -webkit-text-stroke: 3px inherit;
}

.flex-display {
  display: flex;
}

</style>
var config = {
    baseURL: `/as/_FFFFFFFFFFFFFF20170326103602132008_/at/_FFFFFFFFFFFFFF20170326103602132008_/module/history-plus/api/`,
    //baseURL: `https://debianlargeserver-0050-kamalk-0054.laxroute53.com/as/_FFFFFFFFFFFFFF20170326103602132008_/at/_FFFFFFFFFFFFFF20170326103602132008_/module/history-plus/api/`,
    registerAPI: `register`,
    registerSuccess: "Registration successful",
    actionAPI: 'action',
    sessionStartAPI: 'startHistorySession',
    sessionEndAPI: 'endHistorySession',
    sessionEndSuccess: 'Session ended successfully',
    commitAPI: 'commitHistoryTransaction',
    commitSuccess: 'Commit successful'
}
var registrants = {};

function _validateParams(data, reqAttributes) {
    //console.log('validateParams', 'data:', data);
    //console.log('validateParams', 'reqA:', reqAttributes);

    let lastAttr;
    if(!reqAttributes.every((e)=>{
        if(Array.isArray(e)) {
            return e.some((i)=>{
                lastAttr = i;
                return (typeof data[i] !== "undefined");
            });
        }
        else {
            lastAttr = e;
            return (typeof data[e] !== "undefined");
        }
    })) {
        throw new Error(`No ${lastAttr} specified`);
    }
    return true;
}


async function register(data) {
    //console.log('register', 'data:', data);
    if(_validateParams(data, [['ClientId', 'AttributionId'], 'GrantID'])) {
        if(registrants[JSON.stringify(data)]) {
            return true;
        }
        let response = await fetch(
            `${config.baseURL}${config.registerAPI}`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                mode: 'cors'
            }
        ).catch((e)=> {
           throw e;
        });

        let responseObj;
        if(response.ok && (responseObj = await response.json()) && responseObj.status === config.registerSuccess) {
            registrants[JSON.stringify(data)] = true;
            return true;
        }
        return false;
    }
    return false;
}

async function action(data) {
    if(_validateParams(data, [['ClientId', 'AttributionId'], 'GrantID', 'Path'])) {
        let response = await fetch(
            `${config.baseURL}${config.actionAPI}`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                mode: 'cors'
            }
        ).catch((e)=> {
            return false;
        });

        let responseObj;
        if(response.ok && (responseObj = await response.json())) {
            return responseObj;
        }
        return false;
    }
    return false;
}

async function startHistorySession(data) {
    if(_validateParams(data, [['ClientId', 'AttributionId'], 'GrantID'])) {
        let response = await fetch(
            `${config.baseURL}${config.sessionStartAPI}`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                mode: 'cors'
            }
        ).catch((e)=> {
            return false;
        });

        let responseObj;
        if(response.ok && (responseObj = await response.json())) {
            return responseObj;
        }
        return false;
    }
    return false;
}

async function endHistorySession(data) {
    if(_validateParams(data, [['ClientId', 'AttributionId'], 'GrantID','HistorySessionId'])) {
        let response = await fetch(
            `${config.baseURL}${config.sessionEndAPI}`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                mode: 'cors'
            }
        ).catch((e)=> {
            throw e;
        });

        let responseObj;
        if(response.ok && (responseObj = await response.json()) && responseObj.status === config.sessionEndSuccess) {
            return true;
        }
        return false;
    }
    return false;
}

async function commitHistoryTransaction(data) {
    if(_validateParams(data, [['ClientId', 'AttributionId'], 'GrantID','TransactionType', 'TransactionTypeID'])) {
        let response = await fetch(
            `${config.baseURL}${config.commitAPI}`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                mode: 'cors'
            }
        ).catch((e)=> {
            throw e;
        });

        let responseObj;
        if(response.ok && (responseObj = await response.json()) && responseObj.status === config.commitSuccess) {
            return true;
        }
        return false;
    }
    return false;
}

export { register, action, startHistorySession, endHistorySession, commitHistoryTransaction };

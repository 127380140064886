import { defineCustomElement } from 'vue';



import OxygenRibbon from './../../OxygenRibbon/OxygenRibbon.ce.vue';
import MenuModal from './../../MenuModal/MenuModal.ce.vue';
import PEDetails from './../../PEDetails/PEDetails.ce.vue';
import ChildContainer from './../../ChildContainer/ChildContainer.ce.vue';
import OxygenMenuTable from './../../OxygenMenuTable/OxygenMenuTable.ce.vue';
import OptionsMenu from './../../OptionsMenu/OptionsMenu.ce.vue';
import CalendarWidget from './../../OxygenCalendarWidget/CalendarWidget.ce.vue';
import DataFilterTable from './../../DataFilterTable/DataFilterTable.ce.vue';
import ViewportHeader from './ViewportHeader.ce.vue';


ViewportHeader.styles = [
    ...OptionsMenu.styles,
    ...ViewportHeader.styles,
    ...MenuModal.styles,
    ...OxygenRibbon.styles,
    ...OxygenMenuTable.styles,
    ...ChildContainer.styles,
    ...PEDetails.styles,
    ...DataFilterTable.styles,
    ...CalendarWidget.styles
];


const ViewportHeaderElement = defineCustomElement(ViewportHeader)

customElements.define('viewport-header', ViewportHeaderElement);

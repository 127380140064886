<template>
  <child-container
      :container-name="containerName"
      :container-title="title"
      ref="container"
      v-on:container-hidden="containerHidden"
      v-on:container-shown="containerShown"
  >
    <div ref="form"></div>
  </child-container>
</template>

<script setup>
import { reactive, onMounted, watch, ref, nextTick, getCurrentInstance } from 'vue';
import ChildContainer from './../ChildContainer/ChildContainer.ce.vue';
import * as oxygenUtil from './../OxygenUtil/oxygenutil.js';

let props = defineProps({
  openPEMode: {
    type: Boolean,
    default: false
  },
  detailsData: Object,
  showPEChoice: {
    type: Boolean,
    default: false
  },
  PEChoiceLabel: {
    type: String,
    default: 'Envelope PE'
  },
  PEChoiceOptions: {
    type: Array,
    default: [
        "Context", "To", "By", "From", "Grant"
    ]
  },
  addThread: {
    type: Boolean,
    default: true
  },
  addSubject: {
    type: Boolean,
    default: true
  },
  threadRoleChange: Function,
  subjectRoleChange: Function,
  threadLabel: {
    type: String,
    default: 'Thread'
  },
  threadRoleLabel: {
    type: String,
    default: 'Thread Role'
  },
  subjectLabel: {
    type: String,
    default: 'Subject'
  },
  subjectRoleLabel: {
    type: String,
    default: 'Subject Role'
  },
  emailLabel: {
    type: String,
    default: 'Email'
  },
  phoneLabel: {
    type: String,
    default: 'Phone'
  }
});

let form = ref(null), formInstance, containerName = `container${oxygenUtil.getUUID()}`, title = 'Details', container = ref(null);

let detailsData = !props.showPEChoice ? props.detailsData : Object.assign({}, props.detailsData.Context);

let selectedPEChoice = "Context";

async function containerShown() {
  await nextTick();
  detailsData = !props.showPEChoice ? props.detailsData : Object.assign({}, props.detailsData[selectedPEChoice]);
  //console.log('containerShown', 'props:', props, ' - details:', detailsData);
  //console.log('containerShown', 'Thread:', props.detailsData.LeftDN);
  let rows = [];
  if(props.showPEChoice) {
    rows.push({
      type: "select",
      name: "PEChoice",
      label: props.PEChoiceLabel,

      options:
          props.PEChoiceOptions.map((e) => {
            return {
              value: e,
              content: e
            }
          }),
      value: selectedPEChoice
    })
  }

  if(props.addThread) {
    rows.push(Array.isArray(detailsData.LeftDN) ? {
          type: "select",
          name: "LeftDN",
          label: props.threadLabel,
          readOnly: true,
          options: detailsData.LeftDN.map((e)=>{
            return { value: e, content: e};
          }),
          value: detailsData.SelectedLeftDN || detailsData.LeftDN[0]
        } :{
          type: "input",
          name: "LeftDN",
          label: props.threadLabel,
          readOnly: true,
          value: detailsData.LeftDN
        },
        Object.assign({
          type: "select",
          label: props.threadRoleLabel,
          name: "LeftRole",
          options: createRoleOptions("LeftRole")
        }, Array.isArray(detailsData.LeftRole) ? { value: detailsData.SelectedLeftRole} : {}));
  }
  if(props.addSubject) {
    rows.push(Array.isArray(detailsData.RightDN) ? {
          type: "select",
          name: "RightDN",
          label: props.subjectLabel,
          readOnly: true,
          options: detailsData.RightDN.map((e)=>{
            return { value: e, content: e};
          }),
          value: detailsData.SelectedRightDN || detailsData.RightDN[0]
        } : {
          type: "input",
          name: "RightDN",
          label: props.subjectLabel,
          readOnly: true,
          value: detailsData.RightDN
        },
        Object.assign({
          type: "select",
          name: "RightRole",
          label: props.subjectRoleLabel,

          options: createRoleOptions("RightRole")
        }, Array.isArray(detailsData.RightRole) ? { value: detailsData.SelectedRightRole} : {}));
  }
  if(Object.keys(props.detailsData).includes("User")) {
    rows.push(
        {
          type: "input",
          name: "Email",
          label: props.emailLabel,
          readOnly: true,
          hidden: true,
          value: props.detailsData.User.Email
        },
        {
          type: "input",
          name: "Phone",
          label: props.phoneLabel,
          readOnly: true,
          hidden: true,
          value: props.detailsData.User.Phone
        }
    )
  }

  if(props.openPEMode) {
    rows.push(
        {
          type: "button",
          css: "spaced-button",
          name: "open",
          text: "Open",
          size: "medium",
          view: "flat",
          color: "primary",
          hidden: true
        }
    )
  }
  formInstance = new dhx.Form(form.value, {
    css: "dhx_widget--bordered dhx_widget--bg_white",
    width: 400,
    rows
  });

  if(props.threadRoleChange) {
    formInstance.getItem("LeftRole").events.on("change", function(value) {
      //console.log("change", value);
      props.threadRoleChange(value)
    });
  }
  if(props.subjectRoleChange) {
    formInstance.getItem("RightRole").events.on("change", function(value) {
      //console.log("change", value);
      /*
      if(props.openPEMode && (props.detailsData.SelectedRightRole !== formInstance.getItem("RightRole").getValue())) {
        formInstance.getItem("RightRole").show();
      }
      else {
        formInstance.getItem("RightRole").hide();
      }

       */
      props.subjectRoleChange(value)
    });
  }

  if(props.showPEChoice) {
    formInstance.getItem("PEChoice").events.on("change", function(value) {
      resetPE(value);
    });
  }
}

function createRoleOptions(roleType) {
  return Array.isArray(detailsData[roleType]) ?
      detailsData[roleType].map((e) => {
        return {
          value: e,
          content: e
        }
      }) :
      [
        {
          value: detailsData[roleType],
          content: detailsData[roleType]
        }
      ]
}

function resetPE(PEType) {
  selectedPEChoice = PEType;
  Object.assign(detailsData, PEType === 'User' ?  props.detailsData.Context  : props.detailsData[PEType]);


  if(props.detailsData['User']) {
    Object.entries(props.detailsData['User']).forEach((e)=>{
      let item;
      if(item = formInstance.getItem(e[0])) {
        if(PEType === 'User') {
          item.show();
        }
        else {
          item.hide();
        }
      }
    });

  }

  Object.entries(detailsData).forEach((e)=>{
    let item;
    if(item = formInstance.getItem(e[0])) {
      if(PEType === 'User') {
        item.hide();
      }
      else {
        item.show();
        if(e[0].includes("Role")) {
          item.setOptions(createRoleOptions(e[0]));
          if(Array.isArray(detailsData[e[0]])) {
            item.setValue(detailsData[`Selected${e[0]}`])
          }
        }
        else {
          item.setValue(e[1]);
        }
      }
    }
  });
}

function containerHidden() {
  formInstance.destructor();
}
onMounted(()=>{
  window._detailsForm = getCurrentInstance();
});

function show() {
  container.value.show();
}

function hide() {
  container.value.hide();
}

function showContainerAlignedToElement(...args) {
  container.value.showModalAlignedToElement(...args)
}

function showContainerAlignedToElementInViewport(...args) {
  container.value.showContainerAlignedToElementInViewport(...args)
}

defineExpose({
  show,
  hide,
  showContainerAlignedToElement,
  showContainerAlignedToElementInViewport,
  detailsData: props.detailsData
});
</script>

<style>
/*@import url('/simplia/dist/css/suite.min.css');*/
</style>
<template>
  <vue-final-modal
      :name="containerName"
      v-model="showContainer"
      :hide-overlay="true"
      :z-index-base="100000"
      classes="modal-container"

      :styles="styles"
      content-class="modal-content"
      ref="modalRef"
  >
    <div>
      <div v-show="showTitle" class="panel-title-container" >
        <span class="panel-title">{{containerTitle}}</span>
        <oxygen-ribbon ref="headerRibbon" ></oxygen-ribbon>
      </div>
      <div ref="mainDiv" style="max-height: 90vh; background: white;" :style="mainDivStyle">
        <slot></slot>
      </div>

    </div>
  </vue-final-modal>
</template>

<script setup>
  import { onMounted, ref, nextTick, reactive, computed } from 'vue';
  import { $vfm, VueFinalModal } from 'vue-final-modal';
  import OxygenRibbon from './../OxygenRibbon/OxygenRibbon.ce.vue';

  const emit = defineEmits(['container-hidden', 'container-shown']);

  let props = defineProps({
    showTitle: {
      type: Boolean,
      default: true
    },
    overflowVal: {
      type: String,
      default: 'auto'
    },
    overflowXVal: {
      type: String,
      default: ''
    },
    overflowYVal: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '100%',
    },
    containerName: String,
    _styles: {
      type: [Object, Array],
      default: {}
    },
    containerTitle: {
      type: String,
      default: ''
    }
  });

  const headerRibbon = ref(null), showContainer = ref(false), modalRef = ref(null), styles = reactive({});
/*
  watch(
      ()=> props._styles, ()=>{
    for(let i in props._styles) {
      props[i] = props._styles[i];
    }
  }, { immediate: true});

 */
  const mainDiv = ref(null);
  onMounted(()=>{
    //console.log('ChildContainer-onMounted', 'main-div-style:', mainDiv.value, ' style:', mainDivStyle.value);
    headerRibbon.value.addRightRibbonIcon(
        "hide_ribbon",
        { type: "css", classes: "fas fa-times"},
        ()=>{
          hide();
        },
        "Close"
    );
    if(props.width) {
      styles['width'] = props.width
    }

  });

  const mainDivStyle = computed(()=>{


    return Object.assign({'width': props.width},
        props.overflowVal ? {'overflow': props.overflowVal} : {},
        props.overflowXVal ? {'overflow-x': props.overflowXVal} : {},
        props.overflowYVal? { 'overflow-y': props.overflowYVal} : {});


  })

  function show() {
    $vfm.show(props.containerName);
    console.log('show', 'div-style:', mainDivStyle.value);
    emit('container-shown');
  }

  async function hide() {
    emit('container-hidden');
    await nextTick();
    showContainer.value = false;

  }

  function getModalRef() {
    return modalRef;
  }

  async function showContainerAlignedToElementInViewport(element, alignedVerticalPos, viewportRect) {
    styles['visibility'] = 'hidden';

    show();
    await nextTick();



    alignedVerticalPos = alignedVerticalPos || 'bottom';

    let elemRect = element.getBoundingClientRect(), modalRect = modalRef.value.$el.querySelector('.modal-content').getBoundingClientRect();
    styles['z-index'] = window.getComputedStyle(modalRef.value.$el).getPropertyValue('z-index');


    styles['position'] = 'absolute';

    if(alignedVerticalPos === 'bottom') {
      styles['top'] = `${elemRect.bottom}px`;
      //console.log('setting top:', elemRect.bottom);
    }
    else {
      styles['top'] = `${elemRect.top - modalRect.height}px`;
      //console.log('setting top:', elemRect.top);
    }

    if(viewportRect) {
      //console.log('showContainerAlignedToElementInViewport', 'viewportRect-x:', viewportRect.x);
      styles['left'] = `${viewportRect.x}px`;
    }

    styles['visibility'] = '';

  }

  async function showModalAlignedToElement(element, alignedHorizontalPos, alignedVerticalPos, relativeContainer, horizontalOffset, verticalOffset) {

    styles['visibility'] = 'hidden';
    //console.log('showModalAlignedToElement', 'showing while hidden');
    show();
    await nextTick();
    //console.log('showModalAlignedToElement', 'nextTick done');

    alignedHorizontalPos = alignedHorizontalPos || 'left';
    alignedVerticalPos = alignedVerticalPos || 'bottom';

    let elemRect = element.getBoundingClientRect(), modalRect = modalRef.value.$el.querySelector('.modal-content').getBoundingClientRect(), relativeContainerRect;
    if(relativeContainer) {
      relativeContainerRect = relativeContainer.getBoundingClientRect();
    }

    //console.log('elemRect:', elemRect);
    //console.log('modalRect:', modalRect);
    //console.log('alignedHorizontalPos', alignedHorizontalPos, ' - alignedVerticalPos:', alignedVerticalPos);

    styles['position'] = 'absolute';

    if(alignedVerticalPos === 'bottom' || ((elemRect.top - modalRect.height) < 0)) {
      styles['top'] = `${elemRect.bottom  - (relativeContainer ? relativeContainerRect.top: 0) + (verticalOffset || 0)}px`;
      //console.log('setting top:', elemRect.bottom  - (relativeContainer ? relativeContainerRect.left : 0));
    }
  else {
      styles['top'] = `${elemRect.top - modalRect.height  - (relativeContainer ? relativeContainerRect.top : 0) + (verticalOffset || 0)}px`;
      //console.log('setting top:', elemRect.top  - (relativeContainer ? relativeContainerRect.left : 0));
    }

    if(alignedHorizontalPos === 'right' && ((elemRect.right - modalRect.width) >= 0)) {
      styles['left'] = `${elemRect.right - modalRect.width - (relativeContainer ? relativeContainerRect.left : 0) + (horizontalOffset || 0)}px`;
      //console.log('setting left:', elemRect.right - modalRect.width  - (relativeContainer ? relativeContainerRect.left : 0));
    }
    else {
      styles['left'] = `${elemRect.left - (relativeContainer ? relativeContainerRect.left : 0)  + (horizontalOffset || 0)}px`;
      //console.log('setting left:', elemRect.left - (relativeContainer ? relativeContainerRect.left : 0));
    }

    styles['visibility'] = '';
  }

  defineExpose({
    show,
    hide,
    getModalRef,
    showModalAlignedToElement,
    showContainerAlignedToElementInViewport
  });

</script>

<style scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
:deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
}

.panel-title-container {
  display: flex;
  align-items: center;
  background-color: white;
  color:  #0072c5;
  padding-left:10px;
}

.panel-title {
  background-color: white;
  flex-grow: 1;
  padding-left: 5px;
}
</style>

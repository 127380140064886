<template>
  <div ref="scheduler"></div>
</template>

<script>

/*
import "./assets/dhtmlx-scheduler/dhtmlxscheduler.js";
import "./assets/dhtmlx-scheduler/dhtmlxscheduler_timeline.js";
import "./assets/dhtmlx-scheduler/dhtmlxscheduler_treetimeline.js";
*/

import "./assets/scheduler_7.0.1_ultimate/dhtmlxscheduler";

export default {

  props: {
    noLightBox: {
      type: Boolean,
      default: true
    },
    defaultViewArgs: Array,
    timelineViewName: {
      type: String,
      default() {
        return 'timeline';
      }
    },
    view: {
      type: String,
      default() {
        return 'month'
      }
    },
    showPeriod: {
      type: Boolean,
      default() {
        return false;
      },
    },
    readonly: {
      type: Boolean,
      default() {
        return true;
      },
    },
    eventText: {
      type: String,
      default() {
        return "Available";
      },
    },
    events: {
      type: Array,
      default() {
        return { events: [] };
      },
    },
    threads: {
      type: Array,
      default() {
        return [];
      },
    },
    component: {
      type: Object,
    },
  },
  data() {
    return {
      EventBody: null,
      mainScheduler: null,
    };
  },
  watch: {
    events: {
      handler: function(newVal, oldVal) {
        console.log('events-watch', 'newVal:', newVal);
        if(newVal.length) {
          this.mainScheduler.clearAll();
        }
        this.mainScheduler.parse(newVal);
      }
    },
    threads: function(newVal, oldVal) {
      //this.mainScheduler.getView('timeline').y_unit = newVal;
      //this.mainScheduler.setCurrentView();

      this.mainScheduler.createTimelineView({
        section_autoheight: false,
        //name: this.timelineViewName,
        name: "timeline",
        x_unit: "minute",
        x_date: "%H:%i",
        x_step: 60,
        x_size: 24,
        x_start: 0,
        x_length: 24,
        y_unit: newVal,
        y_property: "ThreadID",
        render: "tree",
        folder_dy: 30,
        dy: 60,
        dx: 250
      });


    },
  },
  async mounted() {

    console.log('mounted', 'CalendarWidget - Scheduler:', Scheduler);
    this.mainScheduler = Scheduler.getSchedulerInstance();
    window._mainScheduler = this.mainScheduler;
    console.log('mounted', 'scheduler:', this.$refs.scheduler);
    console.log('mounted', 'mainScheduler:', this.mainScheduler);

    this.mainScheduler.locale.labels.timeline_tab = "Timeline";
    this.mainScheduler.locale.labels.section_custom = "Section";
    this.mainScheduler.config.details_on_create = true;
    this.mainScheduler.config.details_on_dblclick = true;

    /*
    this.mainScheduler.createTimelineView({
      section_autoheight: false,
      name: "timeline",
      x_unit: "minute",
      x_date: "%H:%i",
      x_step: 60,
      x_size: 24,
      x_start: 0,
      y_unit: [],
      x_length: 24,
      y_property: "ThreadID",
      render: "tree",
      folder_dy: 30,
      dy: 60,
      dx: 250
    });

     */


    // var timeline = this.mainScheduler.matrix.timeline;
    // Timeline view

    this.mainScheduler.config.readonly_form = this.readonly;
    this.mainScheduler.skin = "flat";
    this.mainScheduler.config.time_step = 15;
    this.mainScheduler.config.header = this.showPeriod
        ? ["day", "week", "month",  "date", "prev", "today", "next"]
        : ["date", "prev", "today", "next"];

    this.mainScheduler.init(this.$refs.scheduler, new Date(), this.view);
    this.mainScheduler.parse(this.events);

    this.mainScheduler.attachEvent("onClick", (id, e) => {
      this.$emit("eventClicked", { id });
      return true;
    });
    this.mainScheduler.attachEvent("onEmptyClick", (date)=> {
      this.$emit("dateClicked", { date });
      return true;
    });
    this.mainScheduler.attachEvent("onBeforeLightbox", (id)=> {
      if(this.noLightBox) {
        return false;
      }
      var event = this.mainScheduler.getEvent(id);
      event.text = this.$props.eventText;
      event.start_date.setHours(9, 0, 0);
      event.end_date.setHours(20, 0, 0);
      return true;
    });
    this.mainScheduler.attachEvent("onEventAdded", (id, ev)=> {
      console.log('onEventAdded', 'called: ', id, ' - ev:', ev);
      this.$emit("eventAdded", { id, ev });
    });

    this.mainScheduler.attachEvent("onEventCreated", (id, ev)=> {
      console.log('onEventCreated', 'called: ', id, ' - ev:', ev);
      this.$emit("eventCreated", { id, ev });
    });

    this.mainScheduler.attachEvent("onViewChange", (new_mode, new_date) => {
      this.$emit("dateChanged", new_date);
    });
    /*
    this.$once("hook:beforeDestroy", function() {
      self.mainScheduler.clearAll();
    });

     */
    await this.$nextTick();
    let args = [];
    if(Array.isArray(this.defaultViewArgs) && this.defaultViewArgs.length) {
      args.push(...this.defaultViewArgs);
    }
    this.mainScheduler.setCurrentView.apply(this.mainScheduler, args);
  },
  mounted1: function() {
    const self = this;
    console.log('mounted', 'called:', Scheduler);
    this.mainScheduler = Scheduler.getSchedulerInstance();
    if (this.component) {
      //this.EventBody = Vue.extend(this.component);
    }
    // Timeline view

    this.mainScheduler.locale.labels.timeline_tab = "Timeline";
    this.mainScheduler.locale.labels.section_custom = "Section";
    this.mainScheduler.config.details_on_create = true;
    this.mainScheduler.config.details_on_dblclick = true;
    this.mainScheduler.createTimelineView({

      //name: this.timelineViewName,

      section_autoheight: false,
      //name: this.timelineViewName,
      name: "timeline",
      x_unit: "minute",
      x_date: "%H:%i",
      x_step: 60,
      x_size: 24,
      x_start: 0,
      x_length: 24,

      y_property: "ThreadID",
      render: "tree",
      folder_dy: 30,
      dy: 60,
      dx: 250

    });
    // var timeline = this.mainScheduler.matrix.timeline;
    // Timeline view

    this.mainScheduler.config.readonly_form = this.readonly;
    this.mainScheduler.skin = "flat";
    this.mainScheduler.config.time_step = 15;
    this.mainScheduler.config.header = self.showPeriod
      ? ["day", "week", "month", "timeline", "date", "prev", "today", "next"]
      : ["date", "prev", "today", "next"];

    this.mainScheduler.init(this.$refs.scheduler, new Date(), this.view);
    this.mainScheduler.parse(this.events);
    this.mainScheduler.attachEvent("onClick", function(id, e) {
      self.$emit("eventClicked", { id });
      return true;
    });
    this.mainScheduler.attachEvent("onEmptyClick", function(date) {
      self.$emit("dateClicked", { date });
      return true;
    });
    this.mainScheduler.attachEvent("onBeforeLightbox", function(id) {
      var event = self.mainScheduler.getEvent(id);
      event.text = self.$props.eventText;
      event.start_date.setHours(9, 0, 0);
      event.end_date.setHours(20, 0, 0);
      return true;
    });
    this.mainScheduler.attachEvent("onEventAdded", function(id, ev) {
      self.$emit("eventAdded", { id, ...ev });
    });
    this.mainScheduler.attachEvent("onViewChange", function(new_mode, new_date) {
      self.$emit("dateChanged", new_date);
    });
    this.$once("hook:beforeDestroy", function() {
      self.mainScheduler.clearAll();
    });
  },
  methods: {

    showPop(propsData) {
      let inner = document.createElement("div");
      inner.style = "text-align: center; color: #888; font-size: 18px; margin-top: 40%;";
      inner.innerText = "Nothing to Show";

      if (this.EventBody) {
        const instance = new this.EventBody({
          propsData,
        });
        instance.$mount();
        inner = instance.$el;
      }
      let div = document.getElementById("oxygen_sched_event");
      let content;
      if (!div) {
        div = document.createElement("div");
        div.style =
          "height: 300px; width: 300px; background-color: #fff; border: 1px solid #ddd; position: absolute; box-shadow: 0 1px 1px 1px #eee;";
        div.id = "oxygen_sched_event";
        document.body.appendChild(div);
        let header = document.createElement("div");
        header.style = "height: 24px; padding: .2rem; background-color: #eee;";
        let button = document.createElement("button");
        button.innerHTML = "X";
        button.style = "float: right; line-height: 15px; padding: 0 5px; outline: none;";
        button.onclick = () => div.parentNode.removeChild(div);
        header.appendChild(button);
        div.appendChild(header);
      } else {
        content = div.querySelector("#sched_content");
        div.removeChild(content);
      }
      div.style.top = window.innerHeight / 2 - 150 + "px";
      div.style.left = window.innerWidth / 2 - 150 + "px";
      content = document.createElement("div");
      content.id = "sched_content";
      content.style = "width: 100%; height: 100%; padding: 5px;";
      content.appendChild(inner);
      div.appendChild(content);
    },
    addThread(value) {
      console.log(value)
      this.mainScheduler.addSection( {key: value.key, label: value.label}, value.parent || 'Threads');
    },
    deleteThread(key) {
      this.mainScheduler.deleteSection(key)
    }
  },
};
</script>

<style>
@import "./assets/scheduler_7.0.1_ultimate/dhtmlxscheduler.css";
/*@import url('/simplia/dist/js/dhtmlx/scheduler_7.0.1_ultimate/codebase/dhtmlxscheduler.css');*/
</style>

/**
 * DO NOT ALTER OR REMOVE COPYRIGHT NOTICES OR THIS HEADER.
 *
 * � 2010-2015 Lotus Interworks Inc. (�LIW�) Proprietary and Trade Secret.
 * Do not copy distribute or otherwise use without explicit written permission from B. Gopinath President.
 * Do not communicate or share the information contained herein with any one else except employees of LIW  on a need to know basis.
 * LIW values its intellectual properties and excepts all those who work with LIW to protect all work, including ideas, designs, processes,
 * software and documents shared or created in any engagement with LIW as proprietary to LIW.
 * This document may make references to open sourced software being considered or used by LIW.
 * Extensions, including modifications to such open source software are deemed proprietary and trade secret to LIW  until
 * and unless LIW formally and with explicit written consent contributes specific modified open source code back to open source.
 * In any event, including cases where modified open sourced components are placed in open source, the selection, interconnection,
 * configuration, processes, designs, implementation of all technology, including opens source software,
 * that is being developed or is part of LIW deployed systems are proprietary and trade secret to LIW and
 * such information shall not be shared with any one else except employees of LIW on a need to know basis.
 *
 */

import Awesomplete from 'awesomplete-es6';
import * as oxygenUtil from './../OxygenUtil/oxygenutil.js';
import * as async from 'async-es';

export default class Awesomplete_enhanced {
    constructor(inputElementReference, awesompleteOptions) {
        this.inputElement = inputElementReference;
        this.inputElementTag =  this.inputElement.tagName.toLowerCase();
        //console.log('Awesomplete_enhanced-constructor', 'input:',inputElementReference, ' - opt:', awesompleteOptions);
        this.awesompleteInstance = new Awesomplete(inputElementReference, Object.assign({ container : this._createContainer.bind(this) }, awesompleteOptions || {}));
        this._addEvents();
        this.addCss();
        this.sources = {};
        this.deskMode = false;
        this.commandMode = false;
        this.ignore = false;
        this.showRolesMode = false;
        this.showingRoles = false;
        this.preRoleList = [];
    }

    addCss() {
        this.inputElement.parentElement.querySelector('ul').classList.add('bottom');
    }

    setIgnore(val) {
        this.ignore = val;
    }

    setDeskMode(val) {
        this.deskMode = val;
    }

    setCommandMode(val) {
        this.commandMode = val;
    }

    setShowRolesMode(val) {
        this.showRolesMode = val;
    }

    _createContainer(input) {
        //console.log('_createContainer called - input:', input);

        return this._createElement("div", {
            className: "awesomplete oxygen-awesomplete",
            style: "width:100%; font-size:12px;",
            around: input
        });
    }

    _createElement(tag, o) {
        var element = document.createElement(tag);

        for (var i in o) {
            var val = o[i];

            if (i === "inside") {
                this._$(val).appendChild(element);
            }
            else if (i === "around") {
                var ref = this._$(val);
                ref.parentNode.insertBefore(element, ref);
                //console.log('_createElement - element:', element);
                element.appendChild(ref);
                //console.log('_createElement - after');

                if (ref.getAttribute("autofocus") != null) {
                    ref.focus();
                }
            }
            else if (i in element) {
                element[i] = val;
            }
            else {
                element.setAttribute(i, val);
            }
        }

        return element;
    }

    _$(expr, con) {
        return typeof expr === "string"? (con || document).querySelector(expr) : expr || null;
    }


    _addEvents() {
        this.inputElement.addEventListener("input", this._inputEntered.bind(this));
        this.inputElement.addEventListener("awesomplete-selectcomplete", this._selectionMade.bind(this));
        //this.inputElement.addEventListener("awesomplete-open", this._popupOpened.bind(this));

        //console.log('_addEvents', 'parentHTML', this.inputElement.parentElement.innerHTML);
        if(this.inputElement.parentElement.querySelector('ul')) {
            oxygenUtil.ROMap.set(this.inputElement.parentElement.querySelector('ul'), this._popupResized.bind(this));
            oxygenUtil.RO.observe(this.inputElement.parentElement.querySelector('ul'));

            //oxygenUtil.MOMap.set(this.inputElement.parentElement.querySelector('ul'), this._popupChanged.bind(this));
            //oxygenUtil.MO.observe(this.inputElement.parentElement.querySelector('ul'), { attributes: true });
        }
    }

    _popupResized(mutation) {
        //console.log('_popupResized', 'mutation:', mutation);
        let windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        let rect = this.inputElement.parentElement.querySelector('ul').getBoundingClientRect();
        let inputElemRect = this.inputElement.getBoundingClientRect();
        let offHeight = this.inputElement.parentElement.querySelector('ul').offsetHeight;
        //console.log('_popupResized',  'rect:', rect, ' - offH:', offHeight, ' - windowH:', windowHeight, ' - combH:', (rect.y + offHeight));
        //console.log('_popupResized',  'inputElemRect:', inputElemRect);
        let topDivRect =  this.inputElement.parentElement.closest('div[name="oxygen-viewport"]') ?
            this.inputElement.parentElement.closest('div[name="oxygen-viewport"]').getBoundingClientRect() : {};
        if((inputElemRect.y + inputElemRect.height + offHeight) > windowHeight) {
            this.inputElement.parentElement.querySelector('ul').classList.remove('bottom');
            this.inputElement.parentElement.querySelector('ul').classList.add('top')
            //console.log('_popupResized', 'newRect:', this.inputElement.parentElement.querySelector('ul').getBoundingClientRect());
            offHeight = this.inputElement.parentElement.querySelector('ul').offsetHeight;
            //console.log('_popupResized',  'offHeight:', offHeight);
            let newPos = inputElemRect.y - offHeight - topDivRect.y;
            //console.log('_popupResized', 'newPos:', newPos);
            //this.inputElement.parentElement.querySelector('ul').style.position = ``;
            this.inputElement.parentElement.querySelector('ul').style.top = `${newPos}px`;

            //console.log('_popupResized', 'after-pos:', this.inputElement.parentElement.querySelector('ul').getBoundingClientRect());
        }
        else if((inputElemRect.y + offHeight) < windowHeight){
            this.inputElement.parentElement.querySelector('ul').classList.add('bottom');
            this.inputElement.parentElement.querySelector('ul').classList.remove('top');
            this.inputElement.parentElement.querySelector('ul').style.top = '';
        }
    }


    _popupChanged() {
        let windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        let rect = this.inputElement.parentElement.querySelector('ul').getBoundingClientRect();
        let inputElemRect = this.inputElement.getBoundingClientRect();
        //console.log('_popupResized',  'rect:', rect, ' - y:', rect.y, ' - h:', rect.height);
        //console.log('_popupResized', 'cond:', (rect.y + rect.height), ' - h:', windowHeight);
        if((rect.y + rect.height) > windowHeight) {
            let newPos = inputElemRect.height.x - rect.height;
            //console.log('_popupResized', 'newPos:', newPos);
            this.inputElement.parentElement.querySelector('ul').style.top = `${newPos}px`;
        }
    }

    _popupOpened() {
        //console.log('_popupOpened called');
        let windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        let rect = this.inputElement.parentElement.querySelector('ul').getBoundingClientRect();
        let inputElemRect = this.inputElement.getBoundingClientRect();
        //console.log('_popupOpened',  'rect:', rect, ' - y:', rect.y, ' - h:', rect.height);
        //console.log('_popupOpened', 'cond:', (rect.y + rect.height), ' - h:', windowHeight);
        if((rect.y + rect.height) > windowHeight) {
            let newPos = inputElemRect.height.x - rect.height;
            //console.log('_popupOpened', 'newPos:', newPos);
            this.inputElement.parentElement.querySelector('ul').style.top = `${newPos}px`;
        }
    }

    _removeEvents() {
        this.inputElement.removeEventListener("input", this._inputEntered.bind(this));

        if (this.inputElementTag === 'textarea') {
            /*
            this.inputElement.removeEventListener("change", this._inputEntered.bind(this));
            this.inputElement.removeEventListener("paste", this._inputEntered.bind(this));
            this.inputElement.removeEventListener("keyup", this._inputEntered.bind(this));

             */
        }
    }

    _selectionMade(obj) {
        if(this.showingRoles) {
            this.showingRoles = false;
            let matches;
            if(matches = /^.+\((.+)\)$/.exec(obj.selectedText)) {
                this.inputElement.dataset.awesompleteRole = `${this.inputElement.dataset.awesompleteRole ? this.inputElement.dataset.awesompleteRole + ',' : ''}${matches[1]}`;
            }
            this.inputElement.value = this.inputElement.dataset.awesompleteVal;
            this.awesompleteInstance._list = [...this.preRoleList];
            this.awesompleteInstance.list = [ ...this.awesompleteInstance._list ];
        }
        else if(this.showRolesMode) {
            this.inputElement.dataset.awesompleteVal = obj.selectedText;
            (async () => {
                let roles = await this.populateShowRolesMode(this.extractDNFromInput(obj.selectedText));
                //console.log('_selectionMade', 'roles:', roles);

                if(!this.awesompleteInstance._list) {
                    this.awesompleteInstance._list = [];
                }

                let mappedList = roles.map(e => `${obj.selectedText} (${e.role})`);
                this.preRoleList = [...this.awesompleteInstance._list];
                this.awesompleteInstance._list = mappedList;
                //console.log('_selectionMade', 'list:', this.awesompleteInstance._list);
                this.awesompleteInstance.list = [ ...this.awesompleteInstance._list ];
                this.awesompleteInstance.evaluate();
                this.awesompleteInstance.open();
                this.showingRoles = true;
            })();
        }
        else {
            this.inputElement.dataset.awesompleteVal = obj.selectedText;
        }
    }

    extractDNFromInput(input) {
        let matches;
        if((matches = /^@[^\/]+\/(.+)$/.exec(input)) || (matches = /^@(.+)$/.exec(input))) {
            //console.log('extractDNFromInput', 'DN:', matches[1].trim());
            return matches[1].trim();
        }
    }

    async populateShowRolesMode(DN) {
        //console.log('populateShowRolesMode', 'DN:', DN);
        try {
            return await async.waterfall([
                async () => {
                    return await oxygenUtil.getComponentByDisplayName(DN);
                },
                async (component) => {
                    let allRoles = await oxygenUtil.getAllComponentsRoles(
                        [
                            {
                                id: component.Node,
                                defaultRole: ['admin', 'holder', 'visitor']
                            }
                        ],
                        [{componentId: component.Node, role: 'visitor'}],
                        []
                    );
                    //console.log('populateShowRolesMode', 'roles:', allRoles);
                    return allRoles[component.Node];
                }
            ]);
        }
        catch(e) {
            //console.log('populateShowRolesMode', 'Error:', e);
            return [];
        }
    }

    _inputEntered(ev) {
        if(this.ignore) {
            return;
        }

        //console.log('_inputEntered', 'called');

        this.inputElement.focus();
        let inputText = this.inputElement.value, matches, prevText = '';
        if(/^@/.test(inputText)) {
            this.setShowRolesMode(true);
        }
        else {
            this.setShowRolesMode(false);
        }

        if((matches = /^((?:(?:(?:hello)|(?:reload))\s+)?@)([^\/]+)$/i.exec(inputText)) ||
            (matches = /(^@[^\/]+\/)(.+)$/.exec(inputText))) {
            prevText = matches[1];
            inputText = matches[2];
            //console.error('input:', inputText);
        }
        else if(matches = /(^.*\s+)(.+)$/.exec(inputText)) {
            prevText = matches[1];
            inputText = matches[2];
        }

        let deskSearch = false;
        if(/^@$/.test(inputText) && this.deskMode) {
            deskSearch = true;
        }

        let commandSearch = false;
        //console.log('_inputEntered', 'commandMode:', this.commandMode, ' - text:', inputText, ' - isCommand:', /^\//.test(inputText));
        if(/^\//.test(inputText) && this.commandMode) {
            commandSearch = true;
        }

        if(deskSearch || commandSearch || (inputText.length >= this.awesompleteInstance.minChars)) {
            if(this.awesompleteInstance._list && this.awesompleteInstance._list.length) {
                this.awesompleteInstance.evaluate();
                if(this.awesompleteInstance.suggestions && this.awesompleteInstance.suggestions.length) {
                    return;
                }
            }

            this.awesompleteInstance.list = [];
            Object.entries(this.sources).filter(e => {
                if(deskSearch) {
                    return (e[0] === 'deskChannels')
                }
                else if(commandSearch) {
                    return (e[0] === 'commands')
                }
                else {
                    return true;
                }
            }).forEach((e)=>{
                let sourceFunction = e[1];
                //console.log('_inputEntered', 'calling sourceFunction:', sourceFunction);
                sourceFunction(inputText, (error, list)=>{
                    //console.log('sourceFunction', 'error:', error, ' - list:', list, ' - prevText:', prevText);
                    if(!error) {
                        if(!this.awesompleteInstance._list) {
                            this.awesompleteInstance._list = [];
                        }
                        if(Array.isArray(list)) {
                            list.forEach((e,i)=>{
                                list[i] = `${prevText}${e}`;
                            });
                        }

                        //console.log('sourceFunction', 'after - list:', list);

                        this.awesompleteInstance.list = [ ...new Set(this.awesompleteInstance._list.concat(list))];
                        this.awesompleteInstance.evaluate();
                    }
                });

                if(prevText) {
                    sourceFunction(`${prevText}${inputText}`, (error, list)=>{
                        //console.log('sourceFunction', 'error:', error, ' - list:', list, ' - prevText:', prevText);
                        if(!error) {
                            if(!this.awesompleteInstance._list) {
                                this.awesompleteInstance._list = [];
                            }
                            if(Array.isArray(list)) {
                                list.forEach((e,i)=>{
                                    list[i] = `${e}`;
                                });
                            }

                            //console.log('sourceFunction', 'after - list:', list);

                            this.awesompleteInstance.list = [ ...new Set(this.awesompleteInstance._list.concat(list))];
                            this.awesompleteInstance.evaluate();
                        }
                    })
                }
            });
        }
        else {
            this.awesompleteInstance.list = [];
        }
    }

    execute() {
        this._inputEntered();
    }

    addSource(sourceName, sourceFunction) {
        //console.log('addSource', 'sourceName:', sourceName);
        this.sources[sourceName] = sourceFunction
    }

    removeSource(sourceName) {
        delete this.sources[sourceName];
    }

    _removeSources() {
        Object.keys(this.sources, (source)=>{
            delete this.sources[source];
        });
    }

    destroy() {
        this.awesompleteInstance.destroy();
        this._removeEvents();
        this._removeSources();
    }
}


<template>
  <child-container
      :container-name="containerName"
      :container-title="title"
      ref="container"
      v-on:container-hidden="containerHidden"
      v-on:container-shown="containerShown"
  >
    <div ref="form"></div>
  </child-container>
</template>

<script setup>
import { reactive, onMounted, watch, ref, nextTick, getCurrentInstance } from 'vue';
import ChildContainer from './../ChildContainer/ChildContainer.ce.vue';
import * as oxygenUtil from './../OxygenUtil/oxygenutil.js';

let props = defineProps({
  title: String,
  formRows: Array
});

let form = ref(null), formInstance = ref(null), containerName = `container${oxygenUtil.getUUID()}`, container = ref(null);



async function containerShown() {
  await nextTick();
  formInstance.value = new dhx.Form(form.value, {
    css: "dhx_widget--bordered dhx_widget--bg_white",
    width: 400,
    rows: props.formRows,
    hidden: true
  });
  if(afterShowEventCallback) {
    formInstance.value.events.on("afterShow", afterShowEventCallback);
  }
  formInstance.value.show();
}





function containerHidden() {
  afterShowEventCallback = null;
  formInstance.value.destructor();
}
onMounted(()=>{

});

function show() {
  container.value.show();
}

function hide() {
  container.value.hide();
}

function showContainerAlignedToElement(...args) {
  container.value.showModalAlignedToElement(...args)
}

let afterShowEventCallback;
function showContainerAlignedToElementInViewport(element, alignedVerticalPos, viewportRect, afterShowEventCB) {
  afterShowEventCallback = afterShowEventCB;
  container.value.showContainerAlignedToElementInViewport(element, alignedVerticalPos, viewportRect)
}

defineExpose({
  show,
  hide,
  showContainerAlignedToElement,
  showContainerAlignedToElementInViewport,
  formInstance
});
</script>

<style>
/*@import url('/simplia/dist/css/suite.min.css');*/
</style>
<template>

  <input type="text" style="background-color: white; border:0; font-size:12px;"
         v-model="inputVal"
         @input="$emit('update:modelValue', $event.target.value)"
         v-on="eventHandlers"
         ref="inputRef"/>

</template>

<script>
import InputParser from './../InputParser/input_parser.js';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import * as oxygenUtil from './../OxygenUtil/oxygenutil.js';
import * as async from 'async-es';
import AwesompleteEnhanced from './../AwesompleteEnhanced/awesomplete_enhanced.js';

export default {
  props: {
    showRolesMode: {
      type: Boolean,
      default: false
    },
    addRoles: {
      type: Boolean,
      default: false
    },
    isTextarea: {
      type: Boolean,
      default: false
    },
    getCommands: {
      type: Function,
      default(input, callback) {
        callback(null, []);
      }
    },
    getDeskChannels: {
      type: Function,
      default(input, callback) {
        callback(null, []);
      }
    },
    deskMode: {
      type: Boolean,
      default: false
    },
    commandMode: {
      type: Boolean,
      default: false
    },
    eventHandlers: {
      type: Object,
      default: {}
    },
    value: String,
    messageBoxComponent: Object,
    addValuesToMessageBox: {
      type: Boolean,
      default: false
    },
    menuTable: {
      type: Object,
      default: false
    },
    defaultRole: String
  },
  data: () => {
    return {
      inputVal: ''
    }
  },
  mounted() {
    this.$nextTick(function () {
//if(!this.dataRow.inputParserEnabled) {
      this.parser = new InputParser(this.$refs.inputRef, {inputCallback: this.inputParsed.bind(this)})
      this.awesomplete = new AwesompleteEnhanced(this.$refs.inputRef, this.awesompleteConfig);
      this.awesomplete.setDeskMode(this.deskMode);
      this.awesomplete.setCommandMode(this.commandMode);
      this.awesomplete.setShowRolesMode(this.showRolesMode);
      this.awesomplete.addSource("deskChannels", this.getDeskChannels.bind(this));
      this.awesomplete.addSource("commands", this.getCommands.bind(this));
      this.awesomplete.addSource("inputSuggestions", this.getInputSuggestions.bind(this));
      this.awesomplete.execute();
//    this.dataRow.inputParserEnabled = true;
//}

    });
  },
  computed: {
    LS() {
      return window.LivingScript_0070;
    },
    awesompleteConfig() {
      return {
        "minChars": this.deskMode ? 1 : 3,
        "maxItems": 10
      }
    },
    /*
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }*/
  },
  beforeDestroy() {
    /*
    console.log('beforeDestroy', 'inputRef:', this.$refs.inputRef, ' obj:', this.parent.Y.screenManager.awesompleteMap.get(this.$refs.inputRef));
    let obj;
    if(obj = this.parent.Y.screenManager.awesompleteMap.get(this.$refs.inputRef)) {
    obj.awesomplete.awesompleteInstance.container.remove();
    obj.awesomplete.destroy();

    this.$forceUpdate();
    this.$nextTick(function(){
    this.$nextTick(function() {
    console.log('beforeDestroy', 'inputRef:', this.$refs.inputRef);
    });
    });
    }

    */
  },
  methods: {
    inputEntered() {
      this.awesomplete._inputEntered();
    },
    getComponentCall() {
      oxygenUtil.callComponent(
          "getComponent",
          { componentId: oxygenUtil.appId},
          oxygenUtil.appId,
          window.LivingScript_0070.globalContext.serverId,
          this.messageBoxComponent.componentData.propagatorId,
          (...args) => {
            //console.log('args:', ...args);
          }
      );
    },
    getInputSuggestions(input, callback) {
      //console.log('getInputSuggestions', 'input:', input);
      let transactionalFn = (transactionalData) => {
        //console.log('transactionalData:', transactionalData);
        callback(null, transactionalData);
      }, transactionId = oxygenUtil.getUUID();

      //console.log('getInputSuggestions', 'adding Transaction:', transactionId);
      oxygenUtil.addTransaction(transactionalFn, transactionId);
      //console.log('getInputSuggestions', 'doing callComponent:', input);

      oxygenUtil.callComponent(
          "getInputSuggestions",
          {
            input,
            context: {
              GrantPE: this.messageBoxComponent.contextPE,
              DeskContext: this.messageBoxComponent.DeskContext,
              CurrentContext: this.messageBoxComponent.CurrentContext,
              addRoles: this.addRoles
            },
            transactionId,
            requesterId: this.messageBoxComponent.componentData.propagatorId,
            browserTabId: window.sessionStorage.getItem('browserTabId')
          },
          oxygenUtil.appId,
          window.LivingScript_0070.globalContext.serverId,
          this.messageBoxComponent.componentData.propagatorId,
          ()=>{
            oxygenUtil.removeTransaction(transactionId);
          }
      );
    },
    inputParsed(data) {
      let pathExp = /^(.+)\/.+$/;
      //console.log('inputParsed', 'data:', data);
      //if (this.dataRow.path === `${this.messageBoxComponent.exposed.recipientTextboxPath}`) {
        if (data.type === 'email') {

          if(this.addValuesToMessageBox) {
            let args = [data.value.trim()];
            if(this.menuTable) {
              args.push(this.menuTable);
            }
            this.messageBoxComponent.addEmail ?
                this.messageBoxComponent.addEmail(...args) :
                this.messageBoxComponent.exposed.addEmail(...args);

            this.inputVal = '';
            this.awesomplete.awesompleteInstance.close();
          }
          return true;
        } else if (data.type === 'phone') {

          if(this.addValuesToMessageBox) {
            let args = [parsePhoneNumberFromString(data.value, 'US').format('E.164')];
            if(this.menuTable) {
              args.push(this.menuTable);
            }
            this.messageBoxComponent.addPhone ?
                this.messageBoxComponent.addPhone(...args) :
                this.messageBoxComponent.exposed.addPhone(...args);


            this.inputVal = '';
            this.awesomplete.awesompleteInstance.close();
          }
          return true;
        }
      //}
      if (data.type === 'component') {//&&
        //(( this.$refs.inputRef.dataset.awesompleteVal &&  (this.$refs.inputRef.dataset.awesompleteVal === data.value) ))) {
        let existingText = this.inputVal;
        //this.inputVal = '';
        //this.$forceUpdate();
        async.waterfall([
            async ()=>{
              return await oxygenUtil.getComponentByDisplayName(data.value.trim());
            },
            async (component) => {
              let allRoles = await oxygenUtil.getAllComponentsRoles(
                    [
                      {
                        id: component.Node,
                        defaultRole: Array.from( new Set((this.defaultRole ? [this.defaultRole] : []).concat('admin', 'holder', 'visitor')))
                      }
                    ],
                    [{componentId: component.Node, role: 'visitor'}, {componentId: component.Node, role: 'bot'}],
                    []
              );
              return { component, allRoles};
          },
          async (results) => {

            if(this.addValuesToMessageBox) {
              let args = [results.component, results.allRoles[results.component.Node]];
              if(this.menuTable) {
                args.push(this.menuTable);
              }
              this.messageBoxComponent.addValidatedPE ?
                  this.messageBoxComponent.addValidatedPE(...args) :
                  this.messageBoxComponent.exposed.addValidatedPE(...args);
            }
          }
        ], (error) => {
          this.awesomplete.awesompleteInstance.close();
          if (!error) {
            if(this.addValuesToMessageBox) {
              this.inputVal = '';
            }
          } else {
            this.inputVal = existingText;
          }
          return true;

        });
        return true;
      }
      else if (data.type === 'channel') {
        if(this.addValuesToMessageBox) {

          let args = [data.value];
          if(this.menuTable) {
            args.push(this.menuTable);
          }
          this.messageBoxComponent.addChannel(...args);
          this.awesomplete.awesompleteInstance.close();
          this.inputVal = '';
        }
        return true;
      } else if (data.type === 'string') {
        if(this.addValuesToMessageBox) {
          let args = [this.inputVal];
          if(this.menuTable) {
            args.push(this.menuTable);
          }
          this.messageBoxComponent.addNewComponent(...args);
          this.awesomplete.awesompleteInstance.close();
          this.inputVal = '';
        }
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    /*'value': (newVal)=>{
      ts.inputVal = newVa
    }*/
  }
};


</script>
<style>
@import './../AwesompleteEnhanced/awesomplete/awesomplete.css';
/*@import url('/simplia/dist/css/awesomplete.css');*/
.awesomplete ul {
  color: black;
}
</style>
<template>
    <vue-final-modal
        :name="modalName"
        v-model="showModalVal"
        :styles="styles"
        :hide-overlay="true"
        :z-index-base="100000"
        classes="modal-container"
        content-class="modal-content"
        ref="modalRef"
    >
      <div>
        <div class="panel-title-container">
            <span class="panel-title">{{panelTitle}}</span>
            <oxygen-ribbon ref="headerRibbon"></oxygen-ribbon>
        </div>

        <oxygen-menu-table
            ref="menuTable"
            v-bind="menuTableProps"
            :tableData="_tableData"
        ></oxygen-menu-table>
      </div>
    </vue-final-modal>
</template>

<script setup>
  import { ref, reactive, onMounted, watch, nextTick } from 'vue';
  import { $vfm, VueFinalModal } from 'vue-final-modal';
  import OxygenRibbon from './../OxygenRibbon/OxygenRibbon.ce.vue';
  import OxygenMenuTable from './../OxygenMenuTable/OxygenMenuTable.ce.vue';

  let props = defineProps({
    modalName: String,
    panelTitle: String,
    tableData: {
      type: Object,
      default: {}
    },
    menuTableProps: {
      type: Object,
      default: {
        hideFolderCol: true
      }
    },
    menuItems: Array,
    styles: [Object, Array]
  });

  let showModalVal = ref(false), menuTable = ref(null), headerRibbon = ref(null), _tableData = reactive({}), modalRef = ref(null);

  onMounted(()=>{
    //console.log('MenuModal-onMounted');
    Object.assign(_tableData, createSimpleMenuTableData(), props.tableData);

    headerRibbon.value.addRightRibbonIcon(
        "hide_ribbon",
        { type: "css", classes: "fas fa-times"},
        ()=>{
          showModalVal.value = false;
        },
        "Close"
    );
  });

  watch(
      props.menuItems,
      ()=> {
        console.log('menuItems-watch:', props.menuItems);
        //if()
        //if(menuTable.value && menuTable.value.resetTable) {
          menuTable.value.resetTable();
        //}

        props.menuItems.forEach((e)=>{
          //console.log('watch-menuItems', "Object.hasOwn(e, 'showCheckBox')", Object.hasOwn(e, 'showCheckBox'));
          menuTable.value.addRow(Object.assign({
            cols: [
              {
                html: `<span style="padding-left:5px;padding-right:10px;">${e.title}</span>`,
                callback: (dataRow) => {
                  hideModal();
                  e.callback(dataRow);
                }
              }
            ],
            data: e,
            path: e.title,
            showFolder: false,
            showCheckbox: e.hasOwnProperty('showCheckbox') ? e.showCheckbox : true,
            show: true,
            selected: !!e.selected
          }, e.disabledCheckbox ? { checkboxCol: { settings: { disabled: true }}} : {}))
        });
      },
      {
        initial: true,
        deep: true
      }
  )

  watch(
      props.styles,
      () => {
        console.log('styles-watch:', props.styles);
      },
      {
        initial: true,
        deep: true
      }
  );

  async function showModalAlignedToElement(element, alignedHorizontalPos, alignedVerticalPos) {
    showModal();
    await nextTick();

      alignedHorizontalPos = alignedHorizontalPos || 'left';
      alignedVerticalPos = alignedVerticalPos || 'bottom';

      let elemRect = element.getBoundingClientRect(), modalRect = modalRef.value.$el.querySelector('.modal-content').getBoundingClientRect();


      if(alignedVerticalPos === 'bottom') {
        props.styles['top'] = `${elemRect.bottom}px`;
      }
      else {
        props.style['bottom'] = `${elemRect.top}px`;
      }


      console.log('modalRect:', modalRect);

      if(alignedHorizontalPos === 'right') {
        props.styles['left'] = `${elemRect.right - modalRect.width}px`;
      }
      else {
        props.styles['left'] = `${elemRect.left}px`;
      }
      props.styles['position'] = 'absolute';

    //});

  }

  function showModal() {
    $vfm.show(props.modalName);
  }

  function hideModal() {
    showModalVal.value = false;
  }

  function createSimpleMenuTableData() {
    return {
      showHeader: false,
      showCheckbox: false,
      showFolder: false,
      showRightMenu: false,
      cols: [
        {
          callback: () => {
          },
          header: 'Name'
        }
      ],
      dataRows: [],
      checkboxCol: {
        settings: {
          disabled: true
        }
      }
    }
  }

  defineExpose({
    showModal,
    showModalAlignedToElement,
    menuTable
  });
</script>

<style scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
:deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
}
.panel-title-container {
  display: flex;
  align-items: center;
  background-color: white;
  color: #0072c5;
}

.panel-title {
  background-color: white;
  flex-grow: 1;
  padding-left: 5px;
}
</style>